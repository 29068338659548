import { DatePicker, Modal, Space, Spin } from 'antd';
import moment from 'moment';
import React, { useRef } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import Pagination from '../../../../Commoncomponet/Pagination';
import { getConfigretionCMD_TYPEWisePendding_Count_buffer, getConfigretionCMD_TYPEWisePendding_Data_buffer, getHeardbeatData, getScriptCodeIdWiseColumnName, getScriptCodeIdWiseConfigretionData, getStartAndEndDateByTable, onModemConfigByGSMConsumer, reportCreacteInTableName } from '../../../../Commoncomponet/Utils';
import useInterval from "use-interval";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import swal from 'sweetalert2';
import { BootstrapTooltip, exportCSVFile, exportExcelDownload, socket } from '../../../../Commoncomponet/common'
import clockPending from '../../../../assets/img/icons8-clock.gif'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
window.Swal = swal;

export default function Dailyload(props) {
    const {GSMdata}=props
    const [FlagMsg, setFlagMsg] = useState(false)
    const [modelTitle, setmodelTitle] = useState('')
    const [modal, setModal] = useState(false)
    const [MqttMsg, setMqttMsg] = useState('')
    const [time, setTime] = useState(60);
    const [run, setRun] = useState(false);
    const [modalMsg, setmodalMsg] = useState(false)
    const [Read1Msg, setRead1Msg] = useState(false)
    const [Read2Msg, setRead2Msg] = useState(false)
    const [cmd, setcmd] = useState({})
    const [dates, setDates] = useState({ start: '', end: '' })
    const [Index, setIndex] = useState()
    const [PageSize, setPageSize] = useState(50);
    const [numberofPage, setnumberofPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [heartData, setHeartData] = useState([])
    const [loader, setLoader] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [AllData, setAllData] = useState({})
    const [rowData, setRowData] = useState({})
    const [ErrorData, setErrorData] = useState({})
    const [DupData, setDupData] = useState({})
    const [isModalError, setIsModalError] = useState(false)
    const [isModalDup, setIsModalDup] = useState(false)
    const [isModalrow, setIsModalRow] = useState(false)
    const [ColumnName, setColumnName] = useState({})
    const [pendingBuffer, setpendingBuffer] = useState('')
    var [CommandJson, setCommandJson] = useState({})
    var [CommandmsgJson, setCommandmsgJson] = useState({})
    const [CommandStatus, setCommandStatus] = useState('')
    const [sortField, setsortField] = useState('SRNO')
    const [sortDirection, setsortDirection] = useState('desc')
    const ongetConfigretionCMD_TYPEWisePendding_Count_buffer = async () => {
        const data = await getConfigretionCMD_TYPEWisePendding_Count_buffer({ CONSUMER_ID: props.GSMdata.UNIQUE_ID, CMD_TYPE: 'DAILY_LOAD', tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer })
        if (data.success === true) {
            setpendingBuffer(data.data.data)
        } else {
            console.log('something want wrong')
        }
    }
    const mytime = useRef(null)

    const ongetScriptCodeIdWiseConfigretionData = async () => {
        const data = await getScriptCodeIdWiseConfigretionData(props?.GSMdata?.SCRIPTDECODE_ID, { tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer, type: 'DAILY_LOAD' })
        if (data.success === true) {
            const CMD = JSON.parse(data?.data?.data[0]?.METER_QUERY_CMD || '{}')
            if (CMD?.DAILY_LOAD?.READ_METHOD === 3) {
                const obj = { MMD_ID_CMD: 124, SCR_ID_CMD_TYPE: 'DAILY_LOAD', "MMD_ID_OBIS_CODE": CMD?.DAILY_LOAD?.OBIS, "MMD_ID_OBIS_OBJTYPE": CMD?.DAILY_LOAD?.OBJ_TYPE, UNIQUE_ID: props?.GSMdata?.UNIQUE_ID, MMD_ID_IMEI: props?.GSMdata?.IMEI, SCRIPTDECODE_ID: props?.GSMdata?.SCRIPTDECODE_ID, MMD_ID_MSG: new Date().getTime() + 1, MMD_ID_OBIS_READ_METHOD: CMD?.DAILY_LOAD?.READ_METHOD, GSM_BUFFER_TABLE: props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer }

                setFlagMsg(false)
                setmodelTitle(obj.SCR_ID_CMD_TYPE)
                alert(JSON.stringify(obj))
                setCommandJson(obj)

                swal.fire({
                    title: "Are you sure?",
                    text: "This will initiate downlink",
                    icon: "warning",
                    showCancelButton: true,
                }).then(async (willDelete) => {
                    if (willDelete.isConfirmed) {
                        setModal(true)


                        const data = await onModemConfigByGSMConsumer({ config_info: JSON.stringify(obj) })
                        if (data.success === true) {
                            mytime.current = setTimeout(() => {
                                if (Object.keys(CommandStatus).length === 0) {
                                    setCommandStatus({ SCR_STATUS_CMD_ADD: 'Time Out' })
                                }
                            }, 5000)
                        } else {

                        }
                    }
                })
            } else if (CMD?.DAILY_LOAD?.READ_METHOD === 2) {
                setcmd(CMD)
                setRead1Msg(true)
            } else if (CMD?.DAILY_LOAD?.READ_METHOD === 1) {
                setcmd(CMD)
                setRead2Msg(true)
            }

        }
    }
    useInterval(
        () => {
            if (time <= 1) {
                setRun(false);
                setModal(false)

            }
            setTime((preState) => preState - 1);
        },
        time && run ? 1000 : null
    );
    useEffect(() => {
        socket.on('mqttbufferresponse', (massges) => {
            console.log(massges);
            setCommandStatus(massges)

        })
        socket.on('mqttcommandresponse', (massges) => {
            console.log(massges, CommandJson, massges.MSGID == CommandJson.MSGID, massges.SCRIPTDECODE_ID == CommandJson.SCRIPTDECODE_ID, massges.UNIQUE_ID == CommandJson.UNIQUE_ID, massges.CMD_TYPE == CommandJson.CMD_TYPE, massges.DRXTYPE, !massges.STATUS);
            setCommandmsgJson(massges)
        })
    }, [])
    const BfferError = (msg) => {
        console.log(msg.MSGID, CommandJson.MSGID);
        if (msg.MMD_ID_MSG == CommandJson.MMD_ID_MSG) {
            swal.fire('', msg?.SCR_STATUS_CMD_ADD, 'error');
            setCommandStatus('')
            setFlagMsg(true)
            setRun(false);
            setTime(0)
            setModal(false)
        }
    }
    useEffect(() => {
        if (CommandStatus?.SCR_STATUS_CMD_ADD != 'Added In Buffer' && Object.keys(CommandStatus).length > 0) {
            BfferError(CommandStatus)
            clearTimeout(mytime.current);
            mytime.current = null
        } else if (CommandStatus?.SCR_STATUS_CMD_ADD == 'Added In Buffer') {
            setTime(60)
            setRun(true);
            clearTimeout(mytime.current);
            mytime.current = null
        }
    }, [CommandStatus])
    const getPopdata = (massges) => {
        console.log(massges, CommandJson);
        if (massges.MMD_ID_MSG == CommandJson.MMD_ID_MSG && massges.SCRIPTDECODE_ID == CommandJson.SCRIPTDECODE_ID && massges.UNIQUE_ID == CommandJson.UNIQUE_ID && massges.SCR_ID_CMD_TYPE == CommandJson.SCR_ID_CMD_TYPE && massges.MMD_ID_DRXTYPE && !massges.STATUS) {
            setTime(0)
            setRun(false);
            setModal(false)
            if (FlagMsg === false) {
                setmodalMsg(true)
                setMqttMsg(JSON.stringify(massges))
            }

        }
    }

    useEffect(() => {
        if (Object.keys(CommandmsgJson).length > 0) {
            getPopdata(CommandmsgJson)
        }
    }, [CommandmsgJson])
    function zeroPad(num) {
        var str = String(num);
        if (str.length < 2) {
            return "0" + str;
        }

        return str;
    }

    const CloseModel = () => {
        setFlagMsg(true)
        setRun(false);
        setTime(0)
        setModal(false)
        setCommandmsgJson({})
        setCommandStatus('')
    }
    const onSubmitCommand = () => {
        var obj
        if (cmd?.DAILY_LOAD?.READ_METHOD === 1) {
            setRead2Msg(false)
            obj = { MMD_ID_CMD: 124, SCR_ID_CMD_TYPE: 'DAILY_LOAD', "MMD_ID_OBIS_CODE": cmd?.DAILY_LOAD?.OBIS, "MMD_ID_OBIS_OBJTYPE": cmd?.DAILY_LOAD?.OBJ_TYPE, UNIQUE_ID: props?.GSMdata?.UNIQUE_ID, MMD_ID_IMEI: props?.GSMdata?.IMEI, SCRIPTDECODE_ID: props?.GSMdata?.SCRIPTDECODE_ID, MMD_ID_MSG: new Date().getTime() + 1, MMD_ID_START_INDEX: parseInt(Index), MMD_CNT_INDEX: 1, MMD_ID_OBIS_READ_METHOD: cmd?.DAILY_LOAD?.READ_METHOD, GSM_BUFFER_TABLE: props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer }
        } else {
            setRead1Msg(false)
            obj = { MMD_ID_CMD: 124, SCR_ID_CMD_TYPE: 'DAILY_LOAD', "MMD_ID_OBIS_CODE": cmd?.DAILY_LOAD?.OBIS, "MMD_ID_OBIS_OBJTYPE": cmd?.DAILY_LOAD?.OBJ_TYPE, UNIQUE_ID: props?.GSMdata?.UNIQUE_ID, MMD_ID_IMEI: props?.GSMdata?.IMEI, SCRIPTDECODE_ID: props?.GSMdata?.SCRIPTDECODE_ID, MMD_ID_MSG: new Date().getTime() + 1, MMD_TS_START: parseInt(moment(dates?.start, 'DD-MM-YYYY HH:mm:ss').format("X")), MMD_TS_END: parseInt(moment(dates?.end, 'DD-MM-YYYY HH:mm:ss').format("X")), MMD_ID_OBIS_READ_METHOD: cmd?.DAILY_LOAD?.READ_METHOD, GSM_BUFFER_TABLE: props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer }
        }
        setFlagMsg(false)
        setmodelTitle(obj.SCR_ID_CMD_TYPE)
        alert(JSON.stringify(obj))
        setCommandJson(obj)
        swal.fire({
            title: "Are you sure?",
            text: "This will initiate downlink",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                setModal(true)


                const data = await onModemConfigByGSMConsumer({ config_info: JSON.stringify(obj) })
                if (data.success === true) {
                    mytime.current = setTimeout(() => {
                        if (Object.keys(CommandStatus).length === 0) {
                            setCommandStatus({ SCR_STATUS_CMD_ADD: 'Time Out' })
                        }
                    }, 5000)
                } else {

                }
            }
        })
    }

    const onChange = (date, dateString) => {
        setDates({ ...dates, start: dateString })
    };
    const onChanges = (date, dateString) => {
        setDates({ ...dates, end: dateString })
    };
    const ongetScriptCodeIdWiseColumnName = async () => {
        const data = await getScriptCodeIdWiseColumnName(props?.GSMdata?.METER_TYPE)
        if (data.success === true) {
            if (data?.data?.data?.length > 0) {
                setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME || '{}'))
            }
        } else {
            console.log('something want wrong');
        }
    }
    const [StatusCode, setStatusCode] = useState('')
    const ongetHeardbeatData = async () => {

        setLoader(true)

        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        const data = await getHeardbeatData({ start: firstPageIndex, end: PageSize }, { tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.dailydata, colName: sortField, sortDirection: sortDirection })
        if (data.success === true) {
            setHeartData(data.data.data)
            setnumberofPage(data.data.total)
            setLoader(false)
        } else {
            console.log('something want wrong')
            setLoader(false)
            setStatusCode(data?.err?.response?.status)
        }
    }


    useEffect(() => {
        ongetScriptCodeIdWiseColumnName()
        ongetHeardbeatData()
        // ongetConfigretionCMD_TYPEWisePendding_Count_buffer()
    }, []);

    useEffect(() => {
        ongetHeardbeatData()
    }, [currentPage, PageSize])

    const onAllShowData = (item) => {
        setIsModalVisible(true)
        setAllData(item)
    }
    const onRowDate = (row) => {
        setRowData(row)
        setIsModalRow(true)
    }
    const onDuplicate = (dupli) => {
        setDupData(dupli)
        setIsModalDup(true)
    }
    const onError = (err) => {
        setErrorData(err)
        setIsModalError(true)
    }
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    var getDaysArray = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            // arr.push(new Date(dt).getFullYear()+'-'+'0'+(new Date(dt).getMonth() + 1)+'-'+'0'+new Date(dt).getDate());
            arr.push(
                new Date(dt).getFullYear() +
                "-" +
                `${new Date(dt).getMonth() + 1 < 10 ? "0" : ""}` +
                (new Date(dt).getMonth() + 1) +
                "-" +
                `${new Date(dt).getDate() < 10 ? "0" : ""}` +
                new Date(dt).getDate()
            );
        }
        return arr;
    };
    const csvLink = useRef();
    const [CsvData, setCsvData] = useState([])
    const [report, setReport] = useState({ value: '', name: '', start_date: '', end_date: '' })
    const [reportType, setReportType] = useState('')
    const [ismodel, setModel] = useState(false)
    const [ReportLoader, setReportLoader] = useState(false)
    const ReportType = [{ label: 'View Table Info', value: 'View_Table_Info' }, { label: 'All Table Info', value: 'All_Table_Info' }, { label: 'Consumption Report UICO Type', value: 'Consumption-Report-UICO-Type' }, { label: 'Index Report UICI Type', value: 'Index-Report-UICI-Type' }, { label: 'Daily Consumption Report', value: 'Daily-Consumption-Report' },{ label: 'Full Table', value: 'Full_Table' },{ label: 'Daily Consumption Report 2', value: 'RTI1715001855' },{ label: '1st Fortnightly Report', value: 'RTI1715060750' },{ label: '2nd Fortnightly Report', value: 'RTI1715060841' },{ label: 'Daily Consumption Report 3', value: 'RTI1716618289' },{ label: 'Daily Consumption Report 4', value: 'RTI1756899877' }]
    const [ExpireDate, setExpireDate] = useState({})
    const ongetStartAndEndDateByTable = async (tableName, col) => {
        var columnTable = ''
        if (props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1665810585135' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1676094508509' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1676094601754' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1676094645995') {
            columnTable = 'METER_STARTTS'
        } else {
            columnTable = 'METER_ENDTS'
        }
        console.log("Wanted to check if this is calling or not")
        document.body.style.cursor = 'want'
        const data = await getStartAndEndDateByTable({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.dailydata, col: columnTable })
        if (data.success === true) {

            console.log("Expiry Dates == ", data.data)
            setExpireDate(data.data)
            setModel(true)
            document.body.style.cursor = 'default'
        } else {
            console.log("something want wrong")
            document.body.style.cursor = 'default'
        }
    }
    const onHandalReport = (e) => {
        setReport({ ...report, value: e.target.value, name: 'All GSM Consumer Details',start_date: '', end_date: '' })
    }
    const ongetGenretedAllGSMReport = async (Iscsv) => {
        if (reportType != '' && report.value !== '') {

            if (report?.value != 'Full_Table') {
                if(report?.value==='RTI1715060750'||report?.value==='RTI1715060841'){
                    if (report?.start_date=='') {
                        return toast.warn('Please Select Month')
                    }
                    }else{
                if (report?.start_date && report?.end_date) {
                    const x = new Date(moment(report.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                    const y = new Date(moment(report.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                    console.log(x > y, x, y);
                    if (x > y) {
                        return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
                    }
                } else {
                    return toast.warn('Please Select "Start Date" And "End Date"')
                }
            }
            }
            setReportLoader(true)
            var columnTable = ''
            if (props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1665810585135' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1676094508509' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1676094601754' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1676094645995') {
                columnTable = 'METER_ENDTS'
            } else {
                columnTable = 'METER_ENDTS'
            }
            var TypeReport = report?.value
            const data = await reportCreacteInTableName({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.dailydata, col: columnTable, start_date: report?.value == 'Daily-Consumption-Report' ? moment(report.start_date, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD") :report?.value =='RTI1715060750'?moment(report.start_date, "MM-YYYY").format("YYYY-MM-01"):report?.value =='RTI1715060841'?moment(report.start_date, "MM-YYYY").format("YYYY-MM-17"): moment(report.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"), end_date: report?.value == 'Daily-Consumption-Report' ? moment(report.end_date, "DD-MM-YYYY").add(1, 'days').format("YYYY-MM-DD") :report?.value =='RTI1715060750'?moment(report.start_date, "MM-YYYY").format("YYYY-MM-17"):report?.value =='RTI1715060841'?moment(report.start_date, "MM-YYYY").add(1, 'months').format("YYYY-MM-01"): moment(report.end_date, "DD-MM-YYYY").format("YYYY-MM-DD"), IsCondition: report?.value == 'Full_Table' ? false :(props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1686575340512'&&TypeReport=='RTI1756899877')?'MODUID1655982881372_METEID1686575340512': true })
            if (data.success == true) {
                var arrayofData = []
                var headers = {}

                if (data.data.data.length == 0) {
                    setReportLoader(false)
                   return toast.warn('Data Not Found')
                }
                
                if (TypeReport == 'Daily-Consumption-Report'||TypeReport == 'RTI1716618289') {
                    const date_bunch = getDaysArray(moment(report.start_date, "DD-MM-YYYY").format('YYYY-MM-DD'), moment(report.end_date, "DD-MM-YYYY").format('YYYY-MM-DD'))
                    const sapInfo = data?.data?.data || []
                    if(TypeReport == 'RTI1716618289'){
                        headers = {
                            "address_compony_name": "address_compony_name",
                            "INSTALLATION_NUMBER": "INSTALLATION_NUMBER",
                            "CATEGORY2_NAME":"CATEGORY2_NAME"
                        }
                        date_bunch.map((item)=>{headers[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]=moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')})

                    }else{
                    headers = {
                        "address_compony_name": "address_compony_name",
                        "INSTALLATION_NUMBER": "INSTALLATION_NUMBER",
                        "Date": "Date",
                        "Time": "Time",
                        "CONC_CONS": "CONC_CONS",
                        "UNCONV_CONS": "UNCONV_CONS",
                        "CONV_CONS_AL": "CONV_CONS_AL",
                        "UNCONV_CONS_AL": "UNCONV_CONS_AL",
                        "TMIN": "TMIN",
                        "TMAX": "TMAX",
                        "TAV": "TAV",
                        "PMIN": "PMIN",
                        "PMAX": "PMAX",
                        "PAV": "PAV",
                        "QUMIN": "QUMIN",
                        "QUMAX": "QUMAX",
                        "QUAV": "QUAV",
                        "QCMIN": "QCMIN",
                        "QCMAX": "QCMAX",
                        "QCAV": "QCAV",
                        "UNCONVERTED_INDEX": "UNCONVERTED_INDEX",
                        "CONVERTED_INDEX": "CONVERTED_INDEX",
                        "UNCONVERTED_COUNTER": "UNCONVERTED_COUNTER",
                        "CONVERTED_COUNTER": "CONVERTED_COUNTER",
                        "METROL_ALARM": "METROL_ALARM",
                        "TEMPERATURE_ALARM": "TEMPERATURE_ALARM",
                        "PRESSURE_ALARM": "PRESSURE_ALARM"
                    }
                }
                    if (props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1655984521144' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1659760003448' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660278946950' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660279003632' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1675315671021' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660276730566' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1676466785467' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1662788927078' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1680324563157' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1665810585135'|| props?.GSMdata.SCRIPTDECODE_ID =='MODUID1655982881372_METEID1684236463648'||props?.GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1681294010406'||props?.GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1712827870326'|| props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1680786811818'||props?.GSMdata.SCRIPTDECODE_ID =='MODUID1655982881372_METEID1686575340512'||(props?.GSMdata?.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1714730047327'&&TypeReport == 'RTI1716618289')) {
                        var filter_date = [...date_bunch]

                        const findFloat = (keys) => {
                            return ColumnName.Meter_Data.dailydata.filter(into => into.Column == keys).length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == keys)[0].Float : ''
                        }
                        const FloatCONC_CONS = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONC_CONS').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONC_CONS')[0].Float : ''
                        const FloatUNCONV_CONS = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONV_CONS').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONV_CONS')[0].Float : ''
                        const FloatCONV_CONS_AL = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONV_CONS_AL').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONV_CONS_AL')[0].Float : ''
                        const FloatUNCONV_CONS_AL = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONV_CONS_AL').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONV_CONS_AL')[0].Float : ''
                        const FloatTMIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMIN')[0].Float : ''
                        const FloatTMAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMAX')[0].Float : ''
                        const FloatTAV = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TAV').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TAV')[0].Float : ''
                        const FloatPMIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PMIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PMIN')[0].Float : ''
                        const FloatPMAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PMAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PMAX')[0].Float : ''
                        const FloatPAV = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PAV').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PAV')[0].Float : ''
                        const FloatQUMIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QUMIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QUMIN')[0].Float : ''
                        const FloatQUMAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QUMAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QUMAX')[0].Float : ''
                        const FloatQUAV = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QUAV').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QUAV')[0].Float : ''
                        const FloatQCMIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QCMIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QCMIN')[0].Float : ''
                        const FloatQCMAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QCMAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QCMAX')[0].Float : ''
                        const FloatQCAV = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QCAV').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QCAV')[0].Float : ''
                        const FloatUNCONVERTED_INDEX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONVERTED_INDEX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONVERTED_INDEX')[0].Float : ''
                        const FloatCONVERTED_INDEX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONVERTED_INDEX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONVERTED_INDEX')[0].Float : ''
                        const FloatUNCONVERTED_COUNTER = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONVERTED_COUNTER').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONVERTED_COUNTER')[0].Float : ''
                        const FloatCONVERTED_COUNTER = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONVERTED_COUNTER').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONVERTED_COUNTER')[0].Float : ''
                        const FloatMETROL_ALARM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'METROL_ALARM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'METROL_ALARM')[0].Float : ''
                        const FloatTEMPERATURE_ALARM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TEMPERATURE_ALARM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TEMPERATURE_ALARM')[0].Float : ''
                        const FloatPRESSURE_ALARM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRESSURE_ALARM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRESSURE_ALARM')[0].Float : ''


                        const FloatVB_DIFF_DAILY_VBD_D = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB_DIFF_DAILY_VBD_D').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB_DIFF_DAILY_VBD_D')[0].Float : ''
                        const FloatVM_DIFF_DAILY_VMD_D = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM_DIFF_DAILY_VMD_D').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM_DIFF_DAILY_VMD_D')[0].Float : ''
                        const FloatBASE_VOLUME_VB = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'BASE_VOLUME_VB').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'BASE_VOLUME_VB')[0].Float : ''
                        const FloatPRIMARY_VOLUME_VM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRIMARY_VOLUME_VM ').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRIMARY_VOLUME_VM ')[0].Float : ''
                        const FloatMIN_T = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MIN_T').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MIN_T')[0].Float : ''
                        const FloatMAX_T = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MAX_T').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MAX_T')[0].Float : ''
                        const FloatTEMP_T = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TEMP_T').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TEMP_T')[0].Float : ''
                        const FloatMIN_P = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MIN_P').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MIN_P')[0].Float : ''
                        const FloatMAX_P = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MAX_P').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MAX_P')[0].Float : ''
                        const FloatPRESSURE_P = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRESSURE_P').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRESSURE_P')[0].Float : ''

                        const FloatMAX_Q = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MAX_Q').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MAX_Q')[0].Float : ''
                        const FloatFLOW_Q = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'FLOW_Q').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'FLOW_Q')[0].Float : ''

                        const FloatBASE_FLOW_QB = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'BASE_FLOW_QB').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'BASE_FLOW_QB')[0].Float : ''

                        const FloatSPARE_PRIMARY_VOLUME_VS = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'SPARE_PRIMARY_VOLUME_VS').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'SPARE_PRIMARY_VOLUME_VS')[0].Float : '';
                        const FloatSPARE_BASE_VOLUME_VBS = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'SPARE_BASE_VOLUME_VBS').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'SPARE_BASE_VOLUME_VBS')[0].Float : '';
                        // const FloatPRIMARY_VOLUME_VM=ColumnName.Meter_Data.dailydata.filter(into=>into.Column=='PRIMARY_VOLUME_VM').length>0?ColumnName.Meter_Data.dailydata.filter(into=>into.Column=='PRIMARY_VOLUME_VM')[0].Float:''
                        // const FloatBASE_VOLUME_VB=ColumnName.Meter_Data.dailydata.filter(into=>into.Column=='BASE_VOLUME_VB').length>0?ColumnName.Meter_Data.dailydata.filter(into=>into.Column=='BASE_VOLUME_VB')[0].Float:''

                        const FloatDVB_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVB_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVB_HC')[0].Float : ''
                        const FloatDVM_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVM_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVM_HC')[0].Float : ''
                        const FloatST_DVB_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVB_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVB_HC')[0].Float : ''
                        const FloatST_DVM_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVM_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVM_HC')[0].Float : ''
                        const FloatTMIN_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMIN_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMIN_HC')[0].Float : ''
                        const FloatTMAX_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMAX_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMAX_HC')[0].Float : ''
                        const FloatTAVG_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TAVG_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TAVG_HC')[0].Float : ''
                        const FloatP1MIN_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MIN_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MIN_HC')[0].Float : ''
                        const FloatP1MAX_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MAX_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MAX_HC')[0].Float : ''
                        const FloatP1AVG_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1AVG_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1AVG_HC')[0].Float : ''
                        const FloatQMMIN_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMIN_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMIN_HC')[0].Float : ''
                        const FloatQMMAX_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMAX_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMAX_HC')[0].Float : ''
                        const FloatQMAVG_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMAVG_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMAVG_HC')[0].Float : ''
                        const FloatQBMIN_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMIN_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMIN_HC')[0].Float : '';
                        const FloatQBAVG_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBAVG_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBAVG_HC')[0].Float : '';
                        const FloatVM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM')[0].Float : '';
                        const FloatVB = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB')[0].Float : '';
                        const FloatQBMAX_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMAX_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMAX_HC')[0].Float : '';
                        const FloatVBT = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VBT').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VBT')[0].Float : '';

                        const FloatDVB_SUM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVB_SUM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVB_SUM')[0].Float : ''
                        const FloatDVM_SUM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVM_SUM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVM_SUM')[0].Float : ''
                        const FloatST_DVB_SUM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVB_SUM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVB_SUM')[0].Float : ''
                        const FloatTAVG = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TAVG').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TAVG')[0].Float : ''
                        const FloatP1MIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MIN')[0].Float : ''
                        const FloatP1MAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MAX')[0].Float : ''
                        const FloatP1AVG = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1AVG').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1AVG')[0].Float : ''
                        const FloatQMMIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMIN')[0].Float : ''
                        const FloatQMMAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMAX')[0].Float : ''
                        const FloatQMAVG = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMAVG').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMAVG')[0].Float : ''
                        const FloatQBMIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMIN')[0].Float : ''
                        const FloatQBMAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMAX')[0].Float : ''
                        const FloatQBAVG = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBAVG').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBAVG')[0].Float : '';
                        const FloatST_DVM_SUM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVM_SUM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVM_SUM')[0].Float : '';


                        const FloatCOR_VOLUME_M3 = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'COR_VOLUME_M3').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'COR_VOLUME_M3')[0].Float : '';
                        const FloatUNC_VOLUME_M3 = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNC_VOLUME_M3').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNC_VOLUME_M3')[0].Float : '';
                        const FloatAVG_TEMPERATURE = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_TEMPERATURE').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_TEMPERATURE')[0].Float : '';
                        const FloatAVG_PRESSURE = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_PRESSURE').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_PRESSURE')[0].Float : '';
                        const FloatAVG_UNC_FLOW_RATE_M3_H = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_UNC_FLOW_RATE_M3_H').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_UNC_FLOW_RATE_M3_H')[0].Float : '';
                        const FloatAVG_COR_FLOW_RATE_M3_H = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_COR_FLOW_RATE_M3_H').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_COR_FLOW_RATE_M3_H')[0].Float : '';

                        const FloatREADING_RECORD = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'READING_RECORD').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'READING_RECORD')[0].Float : ''
                        const FloatPULSE_END_FINAL_COUNT = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PULSE_END_FINAL_COUNT').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PULSE_END_FINAL_COUNT')[0].Float : ''

                        var statisc_obj = {
                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                            "Date": '',
                            "CONC_CONS": 'Data Not Available',
                            "UNCONV_CONS": 'Data Not Available',
                            "CONV_CONS_AL": 'Data Not Available',
                            "UNCONV_CONS_AL": 'Data Not Available',
                            "TMIN": 'Data Not Available',
                            "TMAX": 'Data Not Available',
                            "TAV": 'Data Not Available',
                            "PMIN": 'Data Not Available',
                            "PMAX": 'Data Not Available',
                            "PAV": 'Data Not Available',
                            "QUMIN": 'Data Not Available',
                            "QUMAX": 'Data Not Available',
                            "QUAV": 'Data Not Available',
                            "QCMIN": 'Data Not Available',
                            "QCMAX": 'Data Not Available',
                            "QCAV": 'Data Not Available',
                            "UNCONVERTED_INDEX": 'Data Not Available',
                            "CONVERTED_INDEX": 'Data Not Available',
                            "UNCONVERTED_COUNTER": 'Data Not Available',
                            "CONVERTED_COUNTER": 'Data Not Available',
                            "METROL_ALARM": 'Data Not Available',
                            "TEMPERATURE_ALARM": 'Data Not Available',
                            "PRESSURE_ALARM": 'Data Not Available',
                            "EVC_Battery_Counter":'Data Not Available'
                        }
                        const result_obj={
                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                            "INSTALLATION_NUMBER": props?.GSMdata?.INSTALLATION_NUMBER,
                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME
                        }
                        sapInfo.length > 0 &&
                            filter_date.map((item) => {
                                if (props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660278946950' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660279003632' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1675315671021'||props?.GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1681294010406'||props?.GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1712827870326'|| props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1680786811818') {
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_ENDTS).add(-1, "days").format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]=FloatVB_DIFF_DAILY_VBD_D ? new Number(daily_data[0].VB_DIFF_DAILY_VBD_D).toFixed(FloatVB_DIFF_DAILY_VBD_D).toString() : daily_data[0].VB_DIFF_DAILY_VBD_D
                                        }else{
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            "Date": moment.utc(daily_data[0].METER_ENDTS).add(-1, "days").format('DD-MM-YYYY'),
                                            "CONC_CONS": FloatVB_DIFF_DAILY_VBD_D ? new Number(daily_data[0].VB_DIFF_DAILY_VBD_D).toFixed(FloatVB_DIFF_DAILY_VBD_D).toString() : daily_data[0].VB_DIFF_DAILY_VBD_D,
                                            "UNCONV_CONS": FloatVM_DIFF_DAILY_VMD_D ? new Number(daily_data[0].VM_DIFF_DAILY_VMD_D).toFixed(FloatVM_DIFF_DAILY_VMD_D).toString() : daily_data[0].VM_DIFF_DAILY_VMD_D,
                                            "CONV_CONS_AL": props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1675315671021' ? 'NA' : FloatVB_DIFF_DAILY_VBD_D ? new Number(daily_data[0].VB_DIFF_DAILY_VBD_D).toFixed(FloatVB_DIFF_DAILY_VBD_D).toString() : daily_data[0].VB_DIFF_DAILY_VBD_D,
                                            "UNCONV_CONS_AL": props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1675315671021' ? 'NA' : FloatVM_DIFF_DAILY_VMD_D ? new Number(daily_data[0].VM_DIFF_DAILY_VMD_D).toFixed(FloatVM_DIFF_DAILY_VMD_D).toString() : daily_data[0].VM_DIFF_DAILY_VMD_D,
                                            "TMIN": FloatMIN_T ? new Number(daily_data[0].MIN_T).toFixed(FloatMIN_T).toString() : daily_data[0].MIN_T,
                                            "TMAX": FloatMAX_T ? new Number(daily_data[0].MAX_T).toFixed(FloatMAX_T).toString() : daily_data[0].MAX_T,
                                            "TAV": FloatTEMP_T ? new Number(daily_data[0].TEMP_T).toFixed(FloatTEMP_T).toString() : daily_data[0].TEMP_T,
                                            "PMIN": FloatMIN_P ? new Number(daily_data[0].MIN_P).toFixed(FloatMIN_P).toString() : daily_data[0].MIN_P,
                                            "PMAX": FloatMAX_P ? new Number(daily_data[0].MAX_P).toFixed(FloatMAX_P).toString() : daily_data[0].MAX_P,
                                            "PAV": FloatPRESSURE_P ? new Number(daily_data[0].PRESSURE_P).toFixed(FloatPRESSURE_P).toString() : daily_data[0].PRESSURE_P,
                                            "QUMIN": 'NA',
                                            "QUMAX": FloatMAX_Q ? new Number(daily_data[0].MAX_Q).toFixed(FloatMAX_Q).toString() : daily_data[0].MAX_Q,
                                            "QUAV": FloatFLOW_Q ? new Number(daily_data[0].FLOW_Q).toFixed(FloatFLOW_Q).toString() : daily_data[0].FLOW_Q,
                                            "QCMIN": 'NA',
                                            "QCMAX": 'NA',
                                            "QCAV": FloatBASE_FLOW_QB ? new Number(daily_data[0].BASE_FLOW_QB).toFixed(FloatBASE_FLOW_QB).toString() : daily_data[0].BASE_FLOW_QB,
                                            "UNCONVERTED_INDEX": FloatPRIMARY_VOLUME_VM ? new Number(daily_data[0].PRIMARY_VOLUME_VM).toFixed(FloatPRIMARY_VOLUME_VM).toString() : daily_data[0].PRIMARY_VOLUME_VM,
                                            "CONVERTED_INDEX": FloatBASE_VOLUME_VB ? new Number(daily_data[0].BASE_VOLUME_VB).toFixed(FloatBASE_VOLUME_VB).toString() : daily_data[0].BASE_VOLUME_VB,
                                            "UNCONVERTED_COUNTER": FloatSPARE_PRIMARY_VOLUME_VS ? new Number(daily_data[0].SPARE_PRIMARY_VOLUME_VS).toFixed(FloatSPARE_PRIMARY_VOLUME_VS).toString() : daily_data[0].SPARE_PRIMARY_VOLUME_VS,
                                            "CONVERTED_COUNTER": FloatSPARE_BASE_VOLUME_VBS ? new Number(daily_data[0].SPARE_BASE_VOLUME_VBS).toFixed(FloatSPARE_BASE_VOLUME_VBS).toString() : daily_data[0].SPARE_BASE_VOLUME_VBS,
                                            "METROL_ALARM": 'NA',
                                            "TEMPERATURE_ALARM": 'NA',
                                            "PRESSURE_ALARM": 'NA'
                                        })
                                    }
                                    } else {
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                    }
                                } else if (props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660276730566') {
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_ENDTS).add(-1, "days").format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]=FloatDVB_HC ? new Number(daily_data[0].DVB_HC).toFixed(FloatDVB_HC).toString() : daily_data[0].DVB_HC
                                            
                                        }else{
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            "Date": moment.utc(daily_data[0].METER_ENDTS).add(-1, "days").format('DD-MM-YYYY'),
                                            "CONC_CONS": FloatDVB_HC ? new Number(daily_data[0].DVB_HC).toFixed(FloatDVB_HC).toString() : daily_data[0].DVB_HC,
                                            "UNCONV_CONS": FloatDVM_HC ? new Number(daily_data[0].DVM_HC).toFixed(FloatDVM_HC).toString() : daily_data[0].DVM_HC,
                                            "CONV_CONS_AL": FloatST_DVB_HC ? new Number(daily_data[0].ST_DVB_HC).toFixed(FloatST_DVB_HC).toString() : daily_data[0].ST_DVB_HC,
                                            "UNCONV_CONS_AL": FloatST_DVM_HC ? new Number(daily_data[0].ST_DVM_HC).toFixed(FloatST_DVM_HC).toString() : daily_data[0].ST_DVM_HC,
                                            "TMIN": FloatTMIN_HC ? new Number(daily_data[0].TMIN_HC).toFixed(FloatTMIN_HC).toString() : daily_data[0].TMIN_HC,
                                            "TMAX": FloatTMAX_HC ? new Number(daily_data[0].TMAX_HC).toFixed(FloatTMAX_HC).toString() : daily_data[0].TMAX_HC,
                                            "TAV": FloatTAVG_HC ? new Number(daily_data[0].TAVG_HC).toFixed(FloatTAVG_HC).toString() : daily_data[0].TAVG_HC,
                                            "PMIN": FloatP1MIN_HC ? new Number(daily_data[0].P1MIN_HC).toFixed(FloatP1MIN_HC).toString() : daily_data[0].P1MIN_HC,
                                            "PMAX": FloatP1MAX_HC ? new Number(daily_data[0].P1MAX_HC).toFixed(FloatP1MAX_HC).toString() : daily_data[0].P1MAX_HC,
                                            "PAV": FloatP1AVG_HC ? new Number(daily_data[0].P1AVG_HC).toFixed(FloatP1AVG_HC).toString() : daily_data[0].P1AVG_HC,
                                            "QUMIN": FloatQMMIN_HC ? new Number(daily_data[0].QMMIN_HC).toFixed(FloatQMMIN_HC).toString() : daily_data[0].QMMIN_HC,
                                            "QUMAX": FloatQMMAX_HC ? new Number(daily_data[0].QMMAX_HC).toFixed(FloatQMMAX_HC).toString() : daily_data[0].QMMAX_HC,
                                            "QUAV": FloatQMAVG_HC ? new Number(daily_data[0].QMAVG_HC).toFixed(FloatQMAVG_HC).toString() : daily_data[0].QMAVG_HC,
                                            "QCMIN": FloatQBMIN_HC ? new Number(daily_data[0].QBMIN_HC).toFixed(FloatQBMIN_HC).toString() : daily_data[0].QBMIN_HC,
                                            "QCMAX": FloatQBMAX_HC ? new Number(daily_data[0].QBMAX_HC).toFixed(FloatQBMAX_HC).toString() : daily_data[0].QBMAX_HC,
                                            "QCAV": FloatQBAVG_HC ? new Number(daily_data[0].QBAVG_HC).toFixed(FloatQBAVG_HC).toString() : daily_data[0].QBAVG_HC,
                                            "UNCONVERTED_INDEX": FloatVM ? new Number(daily_data[0].VM).toFixed(FloatVM).toString() : daily_data[0].VM,
                                            "CONVERTED_INDEX": FloatVB ? new Number(daily_data[0].VB).toFixed(FloatVB).toString() : daily_data[0].VB,
                                            "UNCONVERTED_COUNTER": 'NA',
                                            "CONVERTED_COUNTER": FloatVBT ? new Number(daily_data[0].VBT).toFixed(FloatVBT).toString() : daily_data[0].VBT,
                                            "METROL_ALARM": 'NA',
                                            "TEMPERATURE_ALARM": 'NA',
                                            "PRESSURE_ALARM": 'NA'
                                        })
                                        }
                                    } else {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]='Data Not Available'
                                        }else{
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                        }
                                    }
                                } else if (props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1680324563157'||props?.GSMdata.SCRIPTDECODE_ID =='MODUID1655982881372_METEID1684236463648') {
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_ENDTS).add(-1, "days").format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]=FloatCOR_VOLUME_M3 ? new Number(daily_data[0].COR_VOLUME_M3).toFixed(FloatCOR_VOLUME_M3).toString() : daily_data[0].COR_VOLUME_M3
                                             
                                          } else {
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            "Date": moment.utc(daily_data[0].METER_ENDTS).add(-1, "days").format('DD-MM-YYYY'),
                                            "CONC_CONS": FloatCOR_VOLUME_M3 ? new Number(daily_data[0].COR_VOLUME_M3).toFixed(FloatCOR_VOLUME_M3).toString() : daily_data[0].COR_VOLUME_M3,
                                            "UNCONV_CONS": FloatUNC_VOLUME_M3 ? new Number(daily_data[0].UNC_VOLUME_M3).toFixed(FloatUNC_VOLUME_M3).toString() : daily_data[0].UNC_VOLUME_M3,
                                            "CONV_CONS_AL": 'NA',
                                            "UNCONV_CONS_AL": 'NA',
                                            "TMIN": 'NA',
                                            "TMAX": 'NA',
                                            "TAV": FloatAVG_TEMPERATURE ? new Number(daily_data[0].AVG_TEMPERATURE).toFixed(FloatAVG_TEMPERATURE).toString() : daily_data[0].AVG_TEMPERATURE,
                                            "PMIN": 'NA',
                                            "PMAX": 'NA',
                                            "PAV": FloatAVG_PRESSURE ? new Number(daily_data[0].AVG_PRESSURE).toFixed(FloatAVG_PRESSURE).toString() : daily_data[0].AVG_PRESSURE,
                                            "QUMIN": 'NA',
                                            "QUMAX": 'NA',
                                            "QUAV": FloatAVG_UNC_FLOW_RATE_M3_H ? new Number(daily_data[0].AVG_UNC_FLOW_RATE_M3_H).toFixed(FloatAVG_UNC_FLOW_RATE_M3_H).toString() : daily_data[0].AVG_UNC_FLOW_RATE_M3_H,
                                            "QCMIN": 'NA',
                                            "QCMAX": 'NA',
                                            "QCAV": FloatAVG_COR_FLOW_RATE_M3_H ? new Number(daily_data[0].AVG_COR_FLOW_RATE_M3_H).toFixed(FloatAVG_COR_FLOW_RATE_M3_H).toString() : daily_data[0].AVG_COR_FLOW_RATE_M3_H,
                                            "UNCONVERTED_INDEX": 'NA',
                                            "CONVERTED_INDEX": 'NA',
                                            "UNCONVERTED_COUNTER": 'NA',
                                            "CONVERTED_COUNTER": 'NA',
                                            "METROL_ALARM": 'NA',
                                            "TEMPERATURE_ALARM": 'NA',
                                            "PRESSURE_ALARM": 'NA'
                                        })
                                    }
                                    } else {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]='Data Not Available'
                                        }else{
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                        }
                                    }

                                } else if (props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1676466785467' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1662788927078') {
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_ENDTS).add(-1, "days").format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]= FloatDVB_SUM ? new Number(daily_data[0].DVB_SUM).toFixed(FloatDVB_SUM).toString() : daily_data[0].DVB_SUM
                                        }else{
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            "Date": moment.utc(daily_data[0].METER_ENDTS).add(-1, "days").format('DD-MM-YYYY'),
                                            "CONC_CONS": FloatDVB_SUM ? new Number(daily_data[0].DVB_SUM).toFixed(FloatDVB_SUM).toString() : daily_data[0].DVB_SUM,
                                            "UNCONV_CONS": FloatDVM_SUM ? new Number(daily_data[0].DVM_SUM).toFixed(FloatDVM_SUM).toString() : daily_data[0].DVM_SUM,
                                            "CONV_CONS_AL": FloatST_DVB_SUM ? new Number(daily_data[0].ST_DVB_SUM).toFixed(FloatST_DVB_SUM).toString() : daily_data[0].ST_DVB_SUM,
                                            "UNCONV_CONS_AL": FloatST_DVM_SUM ? new Number(daily_data[0].ST_DVM_SUM).toFixed(FloatST_DVM_SUM).toString() : daily_data[0].ST_DVM_SUM,
                                            "TMIN": FloatTMIN ? new Number(daily_data[0].TMIN).toFixed(FloatTMIN).toString() : daily_data[0].TMIN,
                                            "TMAX": FloatTMAX ? new Number(daily_data[0].TMAX).toFixed(FloatTMAX).toString() : daily_data[0].TMAX,
                                            "TAV": FloatTAVG ? new Number(daily_data[0].TAVG).toFixed(FloatTAVG).toString() : daily_data[0].TAVG,
                                            "PMIN": FloatP1MIN ? new Number(daily_data[0].P1MIN).toFixed(FloatP1MIN).toString() : daily_data[0].P1MIN,
                                            "PMAX": FloatP1MAX ? new Number(daily_data[0].P1MAX).toFixed(FloatP1MAX).toString() : daily_data[0].P1MAX,
                                            "PAV": FloatP1AVG ? new Number(daily_data[0].P1AVG).toFixed(FloatP1AVG).toString() : daily_data[0].P1AVG,
                                            "QUMIN": FloatQMMIN ? new Number(daily_data[0].QMMIN).toFixed(FloatQMMIN).toString() : daily_data[0].QMMIN,
                                            "QUMAX": FloatQMMAX ? new Number(daily_data[0].QMMAX).toFixed(FloatQMMAX).toString() : daily_data[0].QMMAX,
                                            "QUAV": FloatQMAVG ? new Number(daily_data[0].QMAVG).toFixed(FloatQMAVG).toString() : daily_data[0].QMAVG,
                                            "QCMIN": FloatQBMIN ? new Number(daily_data[0].QBMIN).toFixed(FloatQBMIN).toString() : daily_data[0].QBMIN,
                                            "QCMAX": FloatQBMAX ? new Number(daily_data[0].QBMAX).toFixed(FloatQBMAX).toString() : daily_data[0].QBMAX,
                                            "QCAV": FloatQBAVG ? new Number(daily_data[0].QBAVG).toFixed(FloatQBAVG).toString() : daily_data[0].QBAVG,
                                            "UNCONVERTED_INDEX": FloatVM ? new Number(daily_data[0].VM).toFixed(FloatVM).toString() : daily_data[0].VM,
                                            "CONVERTED_INDEX": FloatVB ? new Number(daily_data[0].VB).toFixed(FloatVB).toString() : daily_data[0].VB,
                                            "UNCONVERTED_COUNTER": 'NA',
                                            "CONVERTED_COUNTER": FloatVBT ? new Number(daily_data[0].VBT).toFixed(FloatVBT).toString() : daily_data[0].VBT,
                                            "METROL_ALARM": 'NA',
                                            "TEMPERATURE_ALARM": 'NA',
                                            "PRESSURE_ALARM": 'NA'
                                        })
                                    }

                                    } else {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]='Data Not Available'
                                        }else{
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                        }
                                    }
                                } else if (props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1665810585135') {
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_STARTTS).format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]=FloatREADING_RECORD ? (new Number(daily_data[0].READING_RECORD) * props?.GSMdata?.CONVERSION_FACTOR || 1).toFixed(FloatREADING_RECORD).toString() : (daily_data[0].READING_RECORD * (props?.GSMdata?.CONVERSION_FACTOR || 1))
                                             
                                           }else{
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            "Date": moment.utc(daily_data[0].METER_STARTTS).format('DD-MM-YYYY'),
                                            "CONC_CONS": FloatREADING_RECORD ? (new Number(daily_data[0].READING_RECORD)*GSMdata?.CONVERSION_FACTOR||1).toFixed(FloatREADING_RECORD).toString() : daily_data[0].READING_RECORD,
                                            "UNCONV_CONS": FloatREADING_RECORD ? new Number(daily_data[0].READING_RECORD).toFixed(FloatREADING_RECORD).toString() : daily_data[0].READING_RECORD,
                                            "CONV_CONS_AL": 'NA',
                                            "UNCONV_CONS_AL": 'NA',
                                            "TMIN": 'NA',
                                            "TMAX": 'NA',
                                            "TAV": 'NA',
                                            "PMIN": 'NA',
                                            "PMAX": 'NA',
                                            "PAV": 'NA',
                                            "QUMIN": 'NA',
                                            "QUMAX": 'NA',
                                            "QUAV": 'NA',
                                            "QCMIN": 'NA',
                                            "QCMAX": 'NA',
                                            "QCAV": 'NA',
                                            "UNCONVERTED_INDEX": FloatPULSE_END_FINAL_COUNT ? new Number(daily_data[0].PULSE_END_FINAL_COUNT).toFixed(FloatPULSE_END_FINAL_COUNT).toString() : daily_data[0].PULSE_END_FINAL_COUNT,
                                            "CONVERTED_INDEX": 'NA',
                                            "UNCONVERTED_COUNTER": 'NA',
                                            "CONVERTED_COUNTER": 'NA',
                                            "METROL_ALARM": 'NA',
                                            "TEMPERATURE_ALARM": 'NA',
                                            "PRESSURE_ALARM": 'NA'
                                        })
                                    }

                                    } else {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]='Data Not Available'
                                        }else{
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                        }
                                    }
                                }else if (props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1686575340512') {
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_ENDTS).format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]= findFloat('VB') ? (new Number(daily_data[0].VB)).toFixed(findFloat('VB')).toString() : (daily_data[0].VB)
                                         
                                        }else{
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            "Date": moment.utc(daily_data[0].METER_ENDTS).format('DD-MM-YYYY'),
                                            "CONC_CONS": findFloat('VB') ? (new Number(daily_data[0].VB)).toFixed(findFloat('VB')).toString() : (daily_data[0].VB),
                                            "UNCONV_CONS": findFloat('VM') ? (new Number(daily_data[0].VM)).toFixed(findFloat('VM')).toString() : (daily_data[0].VM),
                                            "CONV_CONS_AL": 'NA',
                                            "UNCONV_CONS_AL": 'NA',
                                            "TMIN": 'NA',
                                            "TMAX": 'NA',
                                            "TAV": 'NA',
                                            "PMIN": 'NA',
                                            "PMAX": 'NA',
                                            "PAV": 'NA',
                                            "QUMIN": 'NA',
                                            "QUMAX": 'NA',
                                            "QUAV": 'NA',
                                            "QCMIN": 'NA',
                                            "QCMAX": 'NA',
                                            "QCAV": 'NA',
                                            "UNCONVERTED_INDEX": 'NA',
                                            "CONVERTED_INDEX": 'NA',
                                            "UNCONVERTED_COUNTER": 'NA',
                                            "CONVERTED_COUNTER": 'NA',
                                            "METROL_ALARM": 'NA',
                                            "TEMPERATURE_ALARM": 'NA',
                                            "PRESSURE_ALARM": 'NA'
                                        })
                                    }

                                    } else {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]='Data Not Available'
                                        }else{
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                        }
                                    }
                                }else if(props?.GSMdata?.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1714730047327'){
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_ENDTS).format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]= findFloat('DVB') ? (new Number(daily_data[0].DVB)).toFixed(findFloat('DVB')).toString() : (daily_data[0].VB)
                                         
                                        }
                                    }else{
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]='Data Not Available'
                                        }
                                    }
                                }
                                else {
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_STARTTS).format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]=  FloatCONC_CONS ? new Number(daily_data[0].CONC_CONS).toFixed(FloatCONC_CONS).toString() : daily_data[0].CONC_CONS
                                           
                                           }else{
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            "Date": moment.utc(daily_data[0].METER_STARTTS).format('DD-MM-YYYY'),
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            "CONC_CONS": FloatCONC_CONS ? new Number(daily_data[0].CONC_CONS).toFixed(FloatCONC_CONS).toString() : daily_data[0].CONC_CONS,
                                            "UNCONV_CONS": FloatUNCONV_CONS ? new Number(daily_data[0].UNCONV_CONS).toFixed(FloatUNCONV_CONS).toString() : daily_data[0].UNCONV_CONS,
                                            "CONV_CONS_AL": FloatCONV_CONS_AL ? new Number(daily_data[0].CONV_CONS_AL).toFixed(FloatCONV_CONS_AL).toString() : daily_data[0].CONV_CONS_AL,
                                            "UNCONV_CONS_AL": FloatUNCONV_CONS_AL ? new Number(daily_data[0].UNCONV_CONS_AL).toFixed(FloatUNCONV_CONS_AL).toString() : daily_data[0].UNCONV_CONS_AL,
                                            "TMIN": FloatTMIN ? new Number(daily_data[0].TMIN).toFixed(FloatTMIN).toString() : daily_data[0].TMIN,
                                            "TMAX": FloatTMAX ? new Number(daily_data[0].TMAX).toFixed(FloatTMAX).toString() : daily_data[0].TMAX,
                                            "TAV": FloatTAV ? new Number(daily_data[0].TAV).toFixed(FloatTAV).toString() : daily_data[0].TAV,
                                            "PMIN": FloatPMIN ? new Number(daily_data[0].PMIN).toFixed(FloatPMIN).toString() : daily_data[0].PMIN,
                                            "PMAX": FloatPMAX ? new Number(daily_data[0].PMAX).toFixed(FloatPMAX).toString() : daily_data[0].PMAX,
                                            "PAV": FloatPAV ? new Number(daily_data[0].PAV).toFixed(FloatPAV).toString() : daily_data[0].PAV,
                                            "QUMIN": FloatQUMIN ? new Number(daily_data[0].QUMIN).toFixed(FloatQUMIN).toString() : daily_data[0].QUMIN,
                                            "QUMAX": FloatQUMAX ? new Number(daily_data[0].QUMAX).toFixed(FloatQUMAX).toString() : daily_data[0].QUMAX,
                                            "QUAV": FloatQUAV ? new Number(daily_data[0].QUAV).toFixed(FloatQUAV).toString() : daily_data[0].QUAV,
                                            "QCMIN": FloatQCMIN ? new Number(daily_data[0].QCMIN).toFixed(FloatQCMIN).toString() : daily_data[0].QCMIN,
                                            "QCMAX": FloatQCMAX ? new Number(daily_data[0].QCMAX).toFixed(FloatQCMAX).toString() : daily_data[0].QCMAX,
                                            "QCAV": FloatQCAV ? new Number(daily_data[0].QCAV).toFixed(FloatQCAV).toString() : daily_data[0].QCAV,
                                            "UNCONVERTED_INDEX": FloatUNCONVERTED_INDEX ? new Number(daily_data[0].UNCONVERTED_INDEX).toFixed(FloatUNCONVERTED_INDEX).toString() : daily_data[0].UNCONVERTED_INDEX,
                                            "CONVERTED_INDEX": FloatCONVERTED_INDEX ? new Number(daily_data[0].CONVERTED_INDEX).toFixed(FloatCONVERTED_INDEX).toString() : daily_data[0].CONVERTED_INDEX,
                                            "UNCONVERTED_COUNTER": FloatUNCONVERTED_COUNTER ? new Number(daily_data[0].UNCONVERTED_COUNTER).toFixed(FloatUNCONVERTED_COUNTER).toString() : daily_data[0].UNCONVERTED_COUNTER,
                                            "CONVERTED_COUNTER": FloatCONVERTED_COUNTER ? new Number(daily_data[0].CONVERTED_COUNTER).toFixed(FloatCONVERTED_COUNTER).toString() : daily_data[0].CONVERTED_COUNTER,
                                            "METROL_ALARM": FloatMETROL_ALARM ? new Number(daily_data[0].METROL_ALARM).toFixed(FloatMETROL_ALARM).toString() : daily_data[0].METROL_ALARM,
                                            "TEMPERATURE_ALARM": FloatTEMPERATURE_ALARM ? new Number(daily_data[0].TEMPERATURE_ALARM).toFixed(FloatTEMPERATURE_ALARM).toString() : daily_data[0].TEMPERATURE_ALARM,
                                            "PRESSURE_ALARM": FloatPRESSURE_ALARM ? new Number(daily_data[0].PRESSURE_ALARM).toFixed(FloatPRESSURE_ALARM).toString() : daily_data[0].PRESSURE_ALARM
                                        })
                                    }
                                    } else {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]='Data Not Available'
                                        }else{
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                        }
                                    }
                                }
                            });
                        if (sapInfo.length > 0) {
                            arrayofData.push(result_obj)
                        } else {
                            filter_date.map((date) => {
                                if(TypeReport == 'RTI1716618289'){
                                    result_obj[moment(date,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]= 'Data Not Available'
                                }else{
                                arrayofData.push({
                                    "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                    "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                    "Date": moment.utc(date).format('DD-MM-YYYY'),
                                    CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                    "CONC_CONS": 'Data Not Available',
                                    "UNCONV_CONS": 'Data Not Available',
                                    "CONV_CONS_AL": 'Data Not Available',
                                    "UNCONV_CONS_AL": 'Data Not Available',
                                    "TMIN": 'Data Not Available',
                                    "TMAX": 'Data Not Available',
                                    "TAV": 'Data Not Available',
                                    "PMIN": 'Data Not Available',
                                    "PMAX": 'Data Not Available',
                                    "PAV": 'Data Not Available',
                                    "QUMIN": 'Data Not Available',
                                    "QUMAX": 'Data Not Available',
                                    "QUAV": 'Data Not Available',
                                    "QCMIN": 'Data Not Available',
                                    "QCMAX": 'Data Not Available',
                                    "QCAV": 'Data Not Available',
                                    "UNCONVERTED_INDEX": 'Data Not Available',
                                    "CONVERTED_INDEX": 'Data Not Available',
                                    "UNCONVERTED_COUNTER": 'Data Not Available',
                                    "CONVERTED_COUNTER": 'Data Not Available',
                                    "METROL_ALARM": 'Data Not Available',
                                    "TEMPERATURE_ALARM": 'Data Not Available',
                                    "PRESSURE_ALARM": 'Data Not Available'
                                })
                            }
                            })
                        }

                        if (reportType === 'csv') {
                            if (report?.value != 'Full_Table') {
                                exportCSVFile(headers, arrayofData, `dailydata_` + '(' + report.start_date + ')' + '_' + '(' + report.end_date + ')' + `_${new Date().getTime()}`)
                            } else {
                                exportCSVFile(headers, arrayofData, `dailydata_${new Date().getTime()}`)

                            }
                            setReportLoader(false)

                        } else {
                            console.log(arrayofData);
                            if (report?.value != 'Full_Table') {
                                exportExcelDownload(arrayofData, `dailydata_` + '(' + report.start_date + ')' + '_' + '(' + report.end_date + ')' + `_${new Date().getTime()}`)
                            } else {
                                exportExcelDownload(arrayofData, `dailydata_${new Date().getTime()}`)

                            }
                            setReportLoader(false)

                        }


                    } else {
                        toast.warn('script Decode Id Not Found')
                        setReportLoader(false)
                    }
                }else if(TypeReport === 'RTI1715060750'||TypeReport==='RTI1715060841'){
                    const sapInfo = data?.data?.data || []
                    const headers={
                        "SRNO": "SRNO",
                        "Customer ID":"Customer ID",
                        "Installation No.":"Installation No.",
                        "B.P No.": "B.P No.",
                        "Consumer Name":"Consumer Name",
                        "Consumer Type": "Consumer Type",
                        "EVC Sr. No.": "EVC Sr. No.",
                        "Date":"Date",
                        "Time": "Time",
                        "COR_VOLUME_M3": "COR_VOLUME_M3",
                        "UNC_VOLUME_M3": "UNC_VOLUME_M3"
                    }
                    const findFloat = (keys) => {
                        return ColumnName.Meter_Data.dailydata.filter(into => into.Column == keys).length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == keys)[0].Float : ''
                    }
                    const submation_obj = sapInfo && sapInfo.length > 0 && sapInfo.reduce((accumulator, object) => {
                        if (props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1680324563157' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1684236463648') {
                            accumulator.col1_value += Number(object.COR_VOLUME_M3);
                            accumulator.col2_value += Number(object.UNC_VOLUME_M3);
                        }
                        return accumulator
                    }, { col1_value: 0, col2_value: 0 })
                    console.log(submation_obj)

                    arrayofData.push({
                        "SRNO": arrayofData.length + 1,
                        "Customer ID": props?.GSMdata?.CUSTOMER_ID,
                        "Installation No.": props?.GSMdata?.INSTALLATION_NUMBER,
                        "B.P No.": props?.GSMdata?.BUSINESS_PARTNER_NUMBER,
                        "Consumer Name": props?.GSMdata?.ADDRESS?.address_compony_name,
                        "Consumer Type": props?.GSMdata?.CONSUMER_TYPE,
                        "EVC Sr. No.": props?.GSMdata?.CONVSERNUM,
                        "Date": moment().format('DD-MM-YYYY'),
                        "Time": moment().format('HH:mm:ss'),
                        "COR_VOLUME_M3": findFloat('COR_VOLUME_M3') ? new Number(submation_obj.col1_value).toFixed(findFloat('COR_VOLUME_M3')).toString() : submation_obj.col1_value,
                        "UNC_VOLUME_M3": findFloat('UNC_VOLUME_M3') ? new Number(submation_obj.col2_value).toFixed(findFloat('UNC_VOLUME_M3')).toString() : submation_obj.col2_value
                    })
                    if (reportType === 'csv') {
                        if (report?.value != 'Full_Table') {
                            exportCSVFile(headers, arrayofData, (TypeReport === 'RTI1715060750'?`1st_Fortnightly_Report_`:'2st_Fortnightly_Report_') + '(' + report.start_date + ')' + '_' + '(' + report.end_date + ')' + `_${new Date().getTime()}`)
                        } else {
                            exportCSVFile(headers, arrayofData, (TypeReport === 'RTI1715060750'?`1st_Fortnightly_Report_`:'2st_Fortnightly_Report_')+`${new Date().getTime()}`)

                        }
                        setReportLoader(false)

                    } else {
                        console.log(arrayofData);
                        if (report?.value != 'Full_Table') {
                            exportExcelDownload(arrayofData, (TypeReport === 'RTI1715060750'?`1st_Fortnightly_Report_`:'2st_Fortnightly_Report_') + '(' + report.start_date + ')' + '_' + '(' + report.end_date + ')' + `_${new Date().getTime()}`)
                        } else {
                            exportExcelDownload(arrayofData, (TypeReport === 'RTI1715060750'?`1st_Fortnightly_Report_`:'2st_Fortnightly_Report_')+`${new Date().getTime()}`)
                        }
                        setReportLoader(false)

                    }
                }else if(TypeReport == 'RTI1715001855'){
                    const date_bunch = getDaysArray(moment(report.start_date, "DD-MM-YYYY").format('YYYY-MM-DD'), moment(report.end_date, "DD-MM-YYYY").format('YYYY-MM-DD'))
                    var filter_date = [...date_bunch]

                    const sapInfo = data?.data?.data || []
                   const headers = {
                        "SRNO": "SRNO",
                        "Customer ID": "Customer ID",
                        "Installation No.": "Installation No.",
                        "B.P No.": "B.P No.",
                        "Consumer Name": "Consumer Name",
                        "Consumer Type": "Consumer Type",
                        "EVC Sr. No.": "EVC Sr. No.",
                        "Date": "Date",
                        "Time": "Time",
                        "COR_VOLUME_M3": "COR_VOLUME_M3",
                        "UNC_VOLUME_M3": "UNC_VOLUME_M3",
                        "AVG_PRESSURE": "AVG_PRESSURE",
                        "AVG_TEMPERATURE": "AVG_TEMPERATURE",
                        "AVG_TOTAL_FACTOR": "AVG_TOTAL_FACTOR",
                        "AVG_UNC_FLOW_RATE_M3_H": "AVG_UNC_FLOW_RATE_M3_H",
                        "AVG_COR_FLOW_RATE_M3_H": "AVG_COR_FLOW_RATE_M3_H",
                        "AVG_BATT_VOLTAGE": "AVG_BATT_VOLTAGE",
                        "ENTRYTS": "ENTRYTS"
                    }

                    if (props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1680324563157' ||props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1684236463648')
                    {
                       const statisc_obj= {
                            "Customer ID": props?.GSMdata?.CUSTOMER_ID,
                            "Installation No.": props?.GSMdata?.INSTALLATION_NUMBER,
                            "B.P No.": props?.GSMdata?.BUSINESS_PARTNER_NUMBER,
                            "Consumer Name": props?.GSMdata?.ADDRESS?.address_compony_name,
                            "Consumer Type": props?.GSMdata?.CONSUMER_TYPE,
                            "EVC Sr. No.": props?.GSMdata?.CONVSERNUM,
                            "Date": 'Data Not Available',
                            "Time": 'Data Not Available',
                            "COR_VOLUME_M3": 'Data Not Available',
                            "UNC_VOLUME_M3": 'Data Not Available',
                            "AVG_TOTAL_FACTOR": 'Data Not Available',
                            "AVG_TEMPERATURE":'Data Not Available',
                            "AVG_PRESSURE": 'Data Not Available',
                            "AVG_UNC_FLOW_RATE_M3_H":'Data Not Available',
                            "AVG_COR_FLOW_RATE_M3_H":'Data Not Available',
                            "AVG_BATT_VOLTAGE":'Data Not Available',
                            ENTRYTS: 'Data Not Available'}
                        const findFloat = (keys) => {
                            return ColumnName.Meter_Data.dailydata.filter(into => into.Column == keys).length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == keys)[0].Float : ''
                        }
                        sapInfo.length > 0 &&
                        filter_date.map((item) => {
                            const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_ENDTS).add(-1, "days").format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        arrayofData.push({SRNO: arrayofData.length + 1,
                                            "Customer ID": props?.GSMdata?.CUSTOMER_ID,
                                            "Installation No.": props?.GSMdata?.INSTALLATION_NUMBER,
                                            "B.P No.":props?.GSMdata?.BUSINESS_PARTNER_NUMBER,
                                            "Consumer Name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "Consumer Type": props?.GSMdata?.CONSUMER_TYPE,
                                            "EVC Sr. No.": props?.GSMdata?.CONVSERNUM,
                                            "Date": moment.utc(daily_data[0].METER_ENDTS).add(-1, "days").format('DD-MM-YYYY'),
                                            "Time": moment.utc(daily_data[0].METER_ENDTS).add(-1, "days").format('HH:mm:ss'),
                                            "COR_VOLUME_M3":findFloat('COR_VOLUME_M3') ? new Number(daily_data[0].COR_VOLUME_M3).toFixed(findFloat('COR_VOLUME_M3')).toString() : daily_data[0].COR_VOLUME_M3,
                                            "UNC_VOLUME_M3":findFloat('UNC_VOLUME_M3') ? new Number(daily_data[0].UNC_VOLUME_M3).toFixed(findFloat('UNC_VOLUME_M3')).toString() : daily_data[0].UNC_VOLUME_M3,
                                            "AVG_TOTAL_FACTOR": findFloat('AVG_TOTAL_FACTOR') ? new Number(daily_data[0].AVG_TOTAL_FACTOR).toFixed(findFloat('AVG_TOTAL_FACTOR')).toString() : daily_data[0].AVG_TOTAL_FACTOR,
                                            "AVG_TEMPERATURE": findFloat('AVG_TEMPERATURE') ? new Number(daily_data[0].AVG_TEMPERATURE).toFixed(findFloat('AVG_TEMPERATURE')).toString() : daily_data[0].AVG_TEMPERATURE,
                                            "AVG_PRESSURE":findFloat('AVG_PRESSURE') ? new Number(daily_data[0].AVG_PRESSURE).toFixed(findFloat('AVG_PRESSURE')).toString() : daily_data[0].AVG_PRESSURE,
                                            "AVG_UNC_FLOW_RATE_M3_H": findFloat('AVG_UNC_FLOW_RATE_M3_H') ? new Number(daily_data[0].AVG_UNC_FLOW_RATE_M3_H).toFixed(findFloat('AVG_UNC_FLOW_RATE_M3_H')).toString() : daily_data[0].AVG_UNC_FLOW_RATE_M3_H,
                                            "AVG_COR_FLOW_RATE_M3_H": findFloat('AVG_COR_FLOW_RATE_M3_H') ? new Number(daily_data[0].AVG_COR_FLOW_RATE_M3_H).toFixed(findFloat('AVG_COR_FLOW_RATE_M3_H')).toString() : daily_data[0].AVG_COR_FLOW_RATE_M3_H,
                                            "AVG_BATT_VOLTAGE": daily_data[0] ? findFloat('AVG_BATT_VOLTAGE') ? new Number(daily_data[0].AVG_BATT_VOLTAGE).toFixed(findFloat('AVG_BATT_VOLTAGE')) : daily_data[0].AVG_BATT_VOLTAGE : "",
                                            ENTRYTS: moment.utc(daily_data[0].ENTRYTS).format('DD-MM-YYYY HH:mm:ss')})
                                    }else{
                                        arrayofData.push({SRNO:arrayofData.length+1, ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                    }
                        })
                        if (sapInfo.length > 0) {
                        } else {
                            filter_date.map((date) => {
                                arrayofData.push({ ...statisc_obj,SRNO: arrayofData.length + 1, 'Date': moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                            })
                        }
                        if (reportType === 'csv') {
                            if (report?.value != 'Full_Table') {
                                exportCSVFile(headers, arrayofData, `Daily_Consumption_Report_2_` + '(' + report.start_date + ')' + '_' + '(' + report.end_date + ')' + `_${new Date().getTime()}`)
                            } else {
                                exportCSVFile(headers, arrayofData, `Daily_Consumption_Report_2_${new Date().getTime()}`)

                            }
                            setReportLoader(false)

                        } else {
                            console.log(arrayofData);
                            if (report?.value != 'Full_Table') {
                                exportExcelDownload(arrayofData, `Daily_Consumption_Report_2_` + '(' + report.start_date + ')' + '_' + '(' + report.end_date + ')' + `_${new Date().getTime()}`)
                            } else {
                                exportExcelDownload(arrayofData, `Daily_Consumption_Report_2_${new Date().getTime()}`)

                            }
                            setReportLoader(false)

                        }
                    } else {
                        toast.warn('script Decode Id Not Found')
                        setReportLoader(false)
                    }

                }else if(TypeReport=='RTI1756899877'){
                    const sapInfo = data?.data?.data || []
                    const date_bunch = getDaysArray(moment(report.start_date, "DD-MM-YYYY").format('YYYY-MM-DD'), moment(report.end_date, "DD-MM-YYYY").format('YYYY-MM-DD'))
                    var filter_date = [...date_bunch]

                    headers = {
                        "SRNO":"Sr. No" ,
                        "CATEGORY0":"CATEGORY0",
                        "CATEGORY1":"CATEGORY1",
                        "CATEGORY2_NAME":"CATEGORY2",
                        "CATEGORY3":"CATEGORY3",
                        "address_compony_name":"Name Of Customer",
                        "INSTALLATION_NUMBER":"Installation No",
                        "Customer_Id":"Customer Id",
                        "Business_Partner_Number":"Business Partner Number ",
                        "Date_of_intsalltion":"Date of intsalltion",
                        "EVC_Profile":"EVC Profile",
                        "Mechanical_Profile":"Mechanical Profile",
                        "Mechanical_MESNO":"Mechanical Meter Serial No",
                        "Date":"Date",
                        "Time":"Time",
                        "CONC_CONS":"Conv. cons.",
                        "UNCONV_CONS":"Unconv. cons.",
                         "CONV_CONS_AL":"Conv. cons. (AL)",
                         "UNCONV_CONS_AL":"Unconv. cons.(AL)",
                        "TMIN":"Tmin",
                         "TMAX":"Tmax",
                         "TAV":"Tav",
                         "PMIN":"Pmin",
                         "PMAX":"Pmax",
                        "PAV":"Pav",
                         "QUMIN":"QUmin",
                         "QUMAX":"QUmax",
                         "QUAV":"QUav",
                         "QCMIN":"QCmin",
                         "QCMAX":"QCmax",
                         "QCAV":"Qcav",
                         "UNCONVERTED_INDEX":"Unconverted Index",
                         "CONVERTED_INDEX":"Converted Index",
                         "UNCONVERTED_COUNTER":"Unconverted counter",
                         "CONVERTED_COUNTER":"Converted counter",
                         "METROL_ALARM":"Alarm in Progress",
                         "TEMPERATURE_ALARM":"Temperature Alarm",
                         "PRESSURE_ALARM":"Pressure Alarm",
                         "EVC_Battery_Counter":"EVC Battery Counter",
                    };
                    if (props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660278946950' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1659760003448'|| props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1680786811818' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1681294010406' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1684236463648' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1684236582202' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1680324563157' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1686575340512'||props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1675315671021'||props?.GSMdata.SCRIPTDECODE_ID =='MODUID1655982881372_METEID1712827870326'||props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1655984521144' || props?.GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METUID1660278946950'||props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660279003632'||props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660276730566'||props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1676466785467'||props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1662788927078'||props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1665810585135'||props?.GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1714730047327' ) {
                        const findFloat = (keys) => {
                            return ColumnName.Meter_Data.dailydata.filter(into => into.Column == keys).length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == keys)[0].Float : ''
                        }
                        const FloatCONC_CONS = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONC_CONS').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONC_CONS')[0].Float : ''
                        const FloatUNCONV_CONS = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONV_CONS').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONV_CONS')[0].Float : ''
                        const FloatCONV_CONS_AL = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONV_CONS_AL').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONV_CONS_AL')[0].Float : ''
                        const FloatUNCONV_CONS_AL = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONV_CONS_AL').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONV_CONS_AL')[0].Float : ''
                        const FloatTMIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMIN')[0].Float : ''
                        const FloatTMAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMAX')[0].Float : ''
                        const FloatTAV = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TAV').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TAV')[0].Float : ''
                        const FloatPMIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PMIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PMIN')[0].Float : ''
                        const FloatPMAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PMAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PMAX')[0].Float : ''
                        const FloatPAV = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PAV').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PAV')[0].Float : ''
                        const FloatQUMIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QUMIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QUMIN')[0].Float : ''
                        const FloatQUMAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QUMAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QUMAX')[0].Float : ''
                        const FloatQUAV = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QUAV').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QUAV')[0].Float : ''
                        const FloatQCMIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QCMIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QCMIN')[0].Float : ''
                        const FloatQCMAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QCMAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QCMAX')[0].Float : ''
                        const FloatQCAV = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QCAV').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QCAV')[0].Float : ''
                        const FloatUNCONVERTED_INDEX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONVERTED_INDEX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONVERTED_INDEX')[0].Float : ''
                        const FloatCONVERTED_INDEX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONVERTED_INDEX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONVERTED_INDEX')[0].Float : ''
                        const FloatUNCONVERTED_COUNTER = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONVERTED_COUNTER').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONVERTED_COUNTER')[0].Float : ''
                        const FloatCONVERTED_COUNTER = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONVERTED_COUNTER').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONVERTED_COUNTER')[0].Float : ''
                        const FloatMETROL_ALARM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'METROL_ALARM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'METROL_ALARM')[0].Float : ''
                        const FloatTEMPERATURE_ALARM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TEMPERATURE_ALARM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TEMPERATURE_ALARM')[0].Float : ''
                        const FloatPRESSURE_ALARM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRESSURE_ALARM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRESSURE_ALARM')[0].Float : ''


                        const FloatVB_DIFF_DAILY_VBD_D = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB_DIFF_DAILY_VBD_D').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB_DIFF_DAILY_VBD_D')[0].Float : ''
                        const FloatVM_DIFF_DAILY_VMD_D = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM_DIFF_DAILY_VMD_D').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM_DIFF_DAILY_VMD_D')[0].Float : ''
                        const FloatBASE_VOLUME_VB = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'BASE_VOLUME_VB').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'BASE_VOLUME_VB')[0].Float : ''
                        const FloatPRIMARY_VOLUME_VM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRIMARY_VOLUME_VM ').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRIMARY_VOLUME_VM ')[0].Float : ''
                        const FloatMIN_T = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MIN_T').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MIN_T')[0].Float : ''
                        const FloatMAX_T = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MAX_T').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MAX_T')[0].Float : ''
                        const FloatTEMP_T = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TEMP_T').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TEMP_T')[0].Float : ''
                        const FloatMIN_P = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MIN_P').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MIN_P')[0].Float : ''
                        const FloatMAX_P = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MAX_P').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MAX_P')[0].Float : ''
                        const FloatPRESSURE_P = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRESSURE_P').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRESSURE_P')[0].Float : ''

                        const FloatMAX_Q = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MAX_Q').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'MAX_Q')[0].Float : ''
                        const FloatFLOW_Q = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'FLOW_Q').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'FLOW_Q')[0].Float : ''

                        const FloatBASE_FLOW_QB = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'BASE_FLOW_QB').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'BASE_FLOW_QB')[0].Float : ''

                        const FloatSPARE_PRIMARY_VOLUME_VS = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'SPARE_PRIMARY_VOLUME_VS').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'SPARE_PRIMARY_VOLUME_VS')[0].Float : '';
                        const FloatSPARE_BASE_VOLUME_VBS = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'SPARE_BASE_VOLUME_VBS').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'SPARE_BASE_VOLUME_VBS')[0].Float : '';
                        // const FloatPRIMARY_VOLUME_VM=ColumnName.Meter_Data.dailydata.filter(into=>into.Column=='PRIMARY_VOLUME_VM').length>0?ColumnName.Meter_Data.dailydata.filter(into=>into.Column=='PRIMARY_VOLUME_VM')[0].Float:''
                        // const FloatBASE_VOLUME_VB=ColumnName.Meter_Data.dailydata.filter(into=>into.Column=='BASE_VOLUME_VB').length>0?ColumnName.Meter_Data.dailydata.filter(into=>into.Column=='BASE_VOLUME_VB')[0].Float:''

                        const FloatDVB_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVB_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVB_HC')[0].Float : ''
                        const FloatDVM_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVM_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVM_HC')[0].Float : ''
                        const FloatST_DVB_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVB_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVB_HC')[0].Float : ''
                        const FloatST_DVM_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVM_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVM_HC')[0].Float : ''
                        const FloatTMIN_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMIN_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMIN_HC')[0].Float : ''
                        const FloatTMAX_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMAX_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TMAX_HC')[0].Float : ''
                        const FloatTAVG_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TAVG_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TAVG_HC')[0].Float : ''
                        const FloatP1MIN_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MIN_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MIN_HC')[0].Float : ''
                        const FloatP1MAX_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MAX_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MAX_HC')[0].Float : ''
                        const FloatP1AVG_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1AVG_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1AVG_HC')[0].Float : ''
                        const FloatQMMIN_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMIN_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMIN_HC')[0].Float : ''
                        const FloatQMMAX_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMAX_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMAX_HC')[0].Float : ''
                        const FloatQMAVG_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMAVG_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMAVG_HC')[0].Float : ''
                        const FloatQBMIN_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMIN_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMIN_HC')[0].Float : '';
                        const FloatQBAVG_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBAVG_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBAVG_HC')[0].Float : '';
                        const FloatVM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM')[0].Float : '';
                        const FloatVB = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB')[0].Float : '';
                        const FloatQBMAX_HC = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMAX_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMAX_HC')[0].Float : '';
                        const FloatVBT = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VBT').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VBT')[0].Float : '';

                        const FloatDVB_SUM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVB_SUM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVB_SUM')[0].Float : ''
                        const FloatDVM_SUM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVM_SUM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'DVM_SUM')[0].Float : ''
                        const FloatST_DVB_SUM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVB_SUM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVB_SUM')[0].Float : ''
                        const FloatTAVG = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TAVG').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'TAVG')[0].Float : ''
                        const FloatP1MIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MIN')[0].Float : ''
                        const FloatP1MAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1MAX')[0].Float : ''
                        const FloatP1AVG = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1AVG').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'P1AVG')[0].Float : ''
                        const FloatQMMIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMIN')[0].Float : ''
                        const FloatQMMAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMMAX')[0].Float : ''
                        const FloatQMAVG = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMAVG').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QMAVG')[0].Float : ''
                        const FloatQBMIN = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMIN').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMIN')[0].Float : ''
                        const FloatQBMAX = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMAX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBMAX')[0].Float : ''
                        const FloatQBAVG = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBAVG').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'QBAVG')[0].Float : '';
                        const FloatST_DVM_SUM = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVM_SUM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVM_SUM')[0].Float : '';


                        const FloatCOR_VOLUME_M3 = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'COR_VOLUME_M3').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'COR_VOLUME_M3')[0].Float : '';
                        const FloatUNC_VOLUME_M3 = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNC_VOLUME_M3').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNC_VOLUME_M3')[0].Float : '';
                        const FloatAVG_TEMPERATURE = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_TEMPERATURE').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_TEMPERATURE')[0].Float : '';
                        const FloatAVG_PRESSURE = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_PRESSURE').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_PRESSURE')[0].Float : '';
                        const FloatAVG_UNC_FLOW_RATE_M3_H = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_UNC_FLOW_RATE_M3_H').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_UNC_FLOW_RATE_M3_H')[0].Float : '';
                        const FloatAVG_COR_FLOW_RATE_M3_H = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_COR_FLOW_RATE_M3_H').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'AVG_COR_FLOW_RATE_M3_H')[0].Float : '';

                        const FloatREADING_RECORD = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'READING_RECORD').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'READING_RECORD')[0].Float : ''
                        const FloatPULSE_END_FINAL_COUNT = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PULSE_END_FINAL_COUNT').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PULSE_END_FINAL_COUNT')[0].Float : ''

                        var statisc_obj = {
                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                            CATEGORY0: props?.GSMdata.ACCOUNT_NAME,
                            CATEGORY1: props?.GSMdata.GA_NAME,
                            CATEGORY3: props?.GSMdata.DIVISION_NAME,
                            Customer_Id: props?.GSMdata.CUSTOMER_ID,
                            Business_Partner_Number: props?.GSMdata.BUSINESS_PARTNER_NUMBER,
                            Date_of_intsalltion: props?.GSMdata.INSTALLATION_DETAILS.Installation_Date_And_Time.split(' ')[0],
                            EVC_Profile:props?.GSMdata.METER_TYPE_NAME,
                            Mechanical_Profile:props?.GSMdata.MECHANICAL_PROFILE_NAME,
                            Mechanical_MESNO:props?.GSMdata.ASMETERSERNUM,
                            "Date": '',
                            "CONC_CONS": 'Data Not Available',
                            "UNCONV_CONS": 'Data Not Available',
                            "CONV_CONS_AL": 'Data Not Available',
                            "UNCONV_CONS_AL": 'Data Not Available',
                            "TMIN": 'Data Not Available',
                            "TMAX": 'Data Not Available',
                            "TAV": 'Data Not Available',
                            "PMIN": 'Data Not Available',
                            "PMAX": 'Data Not Available',
                            "PAV": 'Data Not Available',
                            "QUMIN": 'Data Not Available',
                            "QUMAX": 'Data Not Available',
                            "QUAV": 'Data Not Available',
                            "QCMIN": 'Data Not Available',
                            "QCMAX": 'Data Not Available',
                            "QCAV": 'Data Not Available',
                            "UNCONVERTED_INDEX": 'Data Not Available',
                            "CONVERTED_INDEX": 'Data Not Available',
                            "UNCONVERTED_COUNTER": 'Data Not Available',
                            "CONVERTED_COUNTER": 'Data Not Available',
                            "METROL_ALARM": 'Data Not Available',
                            "TEMPERATURE_ALARM": 'Data Not Available',
                            "PRESSURE_ALARM": 'Data Not Available',
                            "EVC_Battery_Counter":'Data Not Available'
                        }
                        const result_obj={
                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                            "INSTALLATION_NUMBER": props?.GSMdata?.INSTALLATION_NUMBER,
                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME
                        }
                        console.log(sapInfo,data)
                        sapInfo.length > 0 &&
                            filter_date.map((item,ID) => {
                                if (props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660278946950' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660279003632' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1675315671021'||props?.GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1681294010406'||props?.GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1712827870326'|| props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1680786811818') {
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_ENDTS).add(-1, "days").format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            CATEGORY0: props?.GSMdata.ACCOUNT_NAME,
                                            CATEGORY1: props?.GSMdata.GA_NAME,
                                            CATEGORY3: props?.GSMdata.DIVISION_NAME,
                                            Customer_Id: props?.GSMdata.CUSTOMER_ID,
                                            Business_Partner_Number: props?.GSMdata.BUSINESS_PARTNER_NUMBER,
                                            Date_of_intsalltion: props?.GSMdata.INSTALLATION_DETAILS.Installation_Date_And_Time.split(' ')[0],
                                            EVC_Profile:props?.GSMdata.METER_TYPE_NAME,
                                            Mechanical_Profile:props?.GSMdata.MECHANICAL_PROFILE_NAME,
                                            Mechanical_MESNO:props?.GSMdata.ASMETERSERNUM,
                                            
                                            "Date": moment.utc(daily_data[0].METER_ENDTS).add(-1, "days").format('DD-MM-YYYY'),
                                            "CONC_CONS": FloatVB_DIFF_DAILY_VBD_D ? new Number(daily_data[0].VB_DIFF_DAILY_VBD_D).toFixed(FloatVB_DIFF_DAILY_VBD_D).toString() : daily_data[0].VB_DIFF_DAILY_VBD_D,
                                            "UNCONV_CONS": FloatVM_DIFF_DAILY_VMD_D ? new Number(daily_data[0].VM_DIFF_DAILY_VMD_D).toFixed(FloatVM_DIFF_DAILY_VMD_D).toString() : daily_data[0].VM_DIFF_DAILY_VMD_D,
                                            "CONV_CONS_AL": props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1675315671021' ? 'NA' : FloatVB_DIFF_DAILY_VBD_D ? new Number(daily_data[0].VB_DIFF_DAILY_VBD_D).toFixed(FloatVB_DIFF_DAILY_VBD_D).toString() : daily_data[0].VB_DIFF_DAILY_VBD_D,
                                            "UNCONV_CONS_AL": props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1675315671021' ? 'NA' : FloatVM_DIFF_DAILY_VMD_D ? new Number(daily_data[0].VM_DIFF_DAILY_VMD_D).toFixed(FloatVM_DIFF_DAILY_VMD_D).toString() : daily_data[0].VM_DIFF_DAILY_VMD_D,
                                            "TMIN": FloatMIN_T ? new Number(daily_data[0].MIN_T).toFixed(FloatMIN_T).toString() : daily_data[0].MIN_T,
                                            "TMAX": FloatMAX_T ? new Number(daily_data[0].MAX_T).toFixed(FloatMAX_T).toString() : daily_data[0].MAX_T,
                                            "TAV": FloatTEMP_T ? new Number(daily_data[0].TEMP_T).toFixed(FloatTEMP_T).toString() : daily_data[0].TEMP_T,
                                            "PMIN": FloatMIN_P ? new Number(daily_data[0].MIN_P).toFixed(FloatMIN_P).toString() : daily_data[0].MIN_P,
                                            "PMAX": FloatMAX_P ? new Number(daily_data[0].MAX_P).toFixed(FloatMAX_P).toString() : daily_data[0].MAX_P,
                                            "PAV": FloatPRESSURE_P ? new Number(daily_data[0].PRESSURE_P).toFixed(FloatPRESSURE_P).toString() : daily_data[0].PRESSURE_P,
                                            "QUMIN": 'NA',
                                            "QUMAX": FloatMAX_Q ? new Number(daily_data[0].MAX_Q).toFixed(FloatMAX_Q).toString() : daily_data[0].MAX_Q,
                                            "QUAV": FloatFLOW_Q ? new Number(daily_data[0].FLOW_Q).toFixed(FloatFLOW_Q).toString() : daily_data[0].FLOW_Q,
                                            "QCMIN": 'NA',
                                            "QCMAX": 'NA',
                                            "QCAV": FloatBASE_FLOW_QB ? new Number(daily_data[0].BASE_FLOW_QB).toFixed(FloatBASE_FLOW_QB).toString() : daily_data[0].BASE_FLOW_QB,
                                            "UNCONVERTED_INDEX": FloatPRIMARY_VOLUME_VM ? new Number(daily_data[0].PRIMARY_VOLUME_VM).toFixed(FloatPRIMARY_VOLUME_VM).toString() : daily_data[0].PRIMARY_VOLUME_VM,
                                            "CONVERTED_INDEX": FloatBASE_VOLUME_VB ? new Number(daily_data[0].BASE_VOLUME_VB).toFixed(FloatBASE_VOLUME_VB).toString() : daily_data[0].BASE_VOLUME_VB,
                                            "UNCONVERTED_COUNTER": FloatSPARE_PRIMARY_VOLUME_VS ? new Number(daily_data[0].SPARE_PRIMARY_VOLUME_VS).toFixed(FloatSPARE_PRIMARY_VOLUME_VS).toString() : daily_data[0].SPARE_PRIMARY_VOLUME_VS,
                                            "CONVERTED_COUNTER": FloatSPARE_BASE_VOLUME_VBS ? new Number(daily_data[0].SPARE_BASE_VOLUME_VBS).toFixed(FloatSPARE_BASE_VOLUME_VBS).toString() : daily_data[0].SPARE_BASE_VOLUME_VBS,
                                            "METROL_ALARM": 'NA',
                                            "TEMPERATURE_ALARM": 'NA',
                                            "PRESSURE_ALARM": 'NA'
                                        })
                                    
                                    } else {
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                    }
                                }else if (props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660276730566') {
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_ENDTS).add(-1, "days").format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]=FloatDVB_HC ? new Number(daily_data[0].DVB_HC).toFixed(FloatDVB_HC).toString() : daily_data[0].DVB_HC
                                            
                                        }else{
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            "Date": moment.utc(daily_data[0].METER_ENDTS).add(-1, "days").format('DD-MM-YYYY'),
                                            "CONC_CONS": FloatDVB_HC ? new Number(daily_data[0].DVB_HC).toFixed(FloatDVB_HC).toString() : daily_data[0].DVB_HC,
                                            "UNCONV_CONS": FloatDVM_HC ? new Number(daily_data[0].DVM_HC).toFixed(FloatDVM_HC).toString() : daily_data[0].DVM_HC,
                                            "CONV_CONS_AL": FloatST_DVB_HC ? new Number(daily_data[0].ST_DVB_HC).toFixed(FloatST_DVB_HC).toString() : daily_data[0].ST_DVB_HC,
                                            "UNCONV_CONS_AL": FloatST_DVM_HC ? new Number(daily_data[0].ST_DVM_HC).toFixed(FloatST_DVM_HC).toString() : daily_data[0].ST_DVM_HC,
                                            "TMIN": FloatTMIN_HC ? new Number(daily_data[0].TMIN_HC).toFixed(FloatTMIN_HC).toString() : daily_data[0].TMIN_HC,
                                            "TMAX": FloatTMAX_HC ? new Number(daily_data[0].TMAX_HC).toFixed(FloatTMAX_HC).toString() : daily_data[0].TMAX_HC,
                                            "TAV": FloatTAVG_HC ? new Number(daily_data[0].TAVG_HC).toFixed(FloatTAVG_HC).toString() : daily_data[0].TAVG_HC,
                                            "PMIN": FloatP1MIN_HC ? new Number(daily_data[0].P1MIN_HC).toFixed(FloatP1MIN_HC).toString() : daily_data[0].P1MIN_HC,
                                            "PMAX": FloatP1MAX_HC ? new Number(daily_data[0].P1MAX_HC).toFixed(FloatP1MAX_HC).toString() : daily_data[0].P1MAX_HC,
                                            "PAV": FloatP1AVG_HC ? new Number(daily_data[0].P1AVG_HC).toFixed(FloatP1AVG_HC).toString() : daily_data[0].P1AVG_HC,
                                            "QUMIN": FloatQMMIN_HC ? new Number(daily_data[0].QMMIN_HC).toFixed(FloatQMMIN_HC).toString() : daily_data[0].QMMIN_HC,
                                            "QUMAX": FloatQMMAX_HC ? new Number(daily_data[0].QMMAX_HC).toFixed(FloatQMMAX_HC).toString() : daily_data[0].QMMAX_HC,
                                            "QUAV": FloatQMAVG_HC ? new Number(daily_data[0].QMAVG_HC).toFixed(FloatQMAVG_HC).toString() : daily_data[0].QMAVG_HC,
                                            "QCMIN": FloatQBMIN_HC ? new Number(daily_data[0].QBMIN_HC).toFixed(FloatQBMIN_HC).toString() : daily_data[0].QBMIN_HC,
                                            "QCMAX": FloatQBMAX_HC ? new Number(daily_data[0].QBMAX_HC).toFixed(FloatQBMAX_HC).toString() : daily_data[0].QBMAX_HC,
                                            "QCAV": FloatQBAVG_HC ? new Number(daily_data[0].QBAVG_HC).toFixed(FloatQBAVG_HC).toString() : daily_data[0].QBAVG_HC,
                                            "UNCONVERTED_INDEX": FloatVM ? new Number(daily_data[0].VM).toFixed(FloatVM).toString() : daily_data[0].VM,
                                            "CONVERTED_INDEX": FloatVB ? new Number(daily_data[0].VB).toFixed(FloatVB).toString() : daily_data[0].VB,
                                            "UNCONVERTED_COUNTER": 'NA',
                                            "CONVERTED_COUNTER": FloatVBT ? new Number(daily_data[0].VBT).toFixed(FloatVBT).toString() : daily_data[0].VBT,
                                            "METROL_ALARM": 'NA',
                                            "TEMPERATURE_ALARM": 'NA',
                                            "PRESSURE_ALARM": 'NA'
                                        })
                                        }
                                    } else {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]='Data Not Available'
                                        }else{
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                        }
                                    }
                                }else if (props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1676466785467' || props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1662788927078') {
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_ENDTS).add(-1, "days").format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]= FloatDVB_SUM ? new Number(daily_data[0].DVB_SUM).toFixed(FloatDVB_SUM).toString() : daily_data[0].DVB_SUM
                                        }else{
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            "Date": moment.utc(daily_data[0].METER_ENDTS).add(-1, "days").format('DD-MM-YYYY'),
                                            "CONC_CONS": FloatDVB_SUM ? new Number(daily_data[0].DVB_SUM).toFixed(FloatDVB_SUM).toString() : daily_data[0].DVB_SUM,
                                            "UNCONV_CONS": FloatDVM_SUM ? new Number(daily_data[0].DVM_SUM).toFixed(FloatDVM_SUM).toString() : daily_data[0].DVM_SUM,
                                            "CONV_CONS_AL": FloatST_DVB_SUM ? new Number(daily_data[0].ST_DVB_SUM).toFixed(FloatST_DVB_SUM).toString() : daily_data[0].ST_DVB_SUM,
                                            "UNCONV_CONS_AL": FloatST_DVM_SUM ? new Number(daily_data[0].ST_DVM_SUM).toFixed(FloatST_DVM_SUM).toString() : daily_data[0].ST_DVM_SUM,
                                            "TMIN": FloatTMIN ? new Number(daily_data[0].TMIN).toFixed(FloatTMIN).toString() : daily_data[0].TMIN,
                                            "TMAX": FloatTMAX ? new Number(daily_data[0].TMAX).toFixed(FloatTMAX).toString() : daily_data[0].TMAX,
                                            "TAV": FloatTAVG ? new Number(daily_data[0].TAVG).toFixed(FloatTAVG).toString() : daily_data[0].TAVG,
                                            "PMIN": FloatP1MIN ? new Number(daily_data[0].P1MIN).toFixed(FloatP1MIN).toString() : daily_data[0].P1MIN,
                                            "PMAX": FloatP1MAX ? new Number(daily_data[0].P1MAX).toFixed(FloatP1MAX).toString() : daily_data[0].P1MAX,
                                            "PAV": FloatP1AVG ? new Number(daily_data[0].P1AVG).toFixed(FloatP1AVG).toString() : daily_data[0].P1AVG,
                                            "QUMIN": FloatQMMIN ? new Number(daily_data[0].QMMIN).toFixed(FloatQMMIN).toString() : daily_data[0].QMMIN,
                                            "QUMAX": FloatQMMAX ? new Number(daily_data[0].QMMAX).toFixed(FloatQMMAX).toString() : daily_data[0].QMMAX,
                                            "QUAV": FloatQMAVG ? new Number(daily_data[0].QMAVG).toFixed(FloatQMAVG).toString() : daily_data[0].QMAVG,
                                            "QCMIN": FloatQBMIN ? new Number(daily_data[0].QBMIN).toFixed(FloatQBMIN).toString() : daily_data[0].QBMIN,
                                            "QCMAX": FloatQBMAX ? new Number(daily_data[0].QBMAX).toFixed(FloatQBMAX).toString() : daily_data[0].QBMAX,
                                            "QCAV": FloatQBAVG ? new Number(daily_data[0].QBAVG).toFixed(FloatQBAVG).toString() : daily_data[0].QBAVG,
                                            "UNCONVERTED_INDEX": FloatVM ? new Number(daily_data[0].VM).toFixed(FloatVM).toString() : daily_data[0].VM,
                                            "CONVERTED_INDEX": FloatVB ? new Number(daily_data[0].VB).toFixed(FloatVB).toString() : daily_data[0].VB,
                                            "UNCONVERTED_COUNTER": 'NA',
                                            "CONVERTED_COUNTER": FloatVBT ? new Number(daily_data[0].VBT).toFixed(FloatVBT).toString() : daily_data[0].VBT,
                                            "METROL_ALARM": 'NA',
                                            "TEMPERATURE_ALARM": 'NA',
                                            "PRESSURE_ALARM": 'NA'
                                        })
                                    }

                                    } else {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]='Data Not Available'
                                        }else{
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                        }
                                    }
                                }  else if (props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1680324563157'||props?.GSMdata.SCRIPTDECODE_ID =='MODUID1655982881372_METEID1684236463648') {
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_ENDTS).add(-1, "days").format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            CATEGORY0: props?.GSMdata.ACCOUNT_NAME,
                                            CATEGORY1: props?.GSMdata.GA_NAME,
                                            CATEGORY3: props?.GSMdata.DIVISION_NAME,
                                            Customer_Id: props?.GSMdata.CUSTOMER_ID,
                                            Business_Partner_Number: props?.GSMdata.BUSINESS_PARTNER_NUMBER,
                                            Date_of_intsalltion: props?.GSMdata.INSTALLATION_DETAILS.Installation_Date_And_Time.split(' ')[0],
                                            EVC_Profile:props?.GSMdata.METER_TYPE_NAME,
                                            Mechanical_Profile:props?.GSMdata.MECHANICAL_PROFILE_NAME,
                                            Mechanical_MESNO:props?.GSMdata.ASMETERSERNUM,
                                            "Date": moment.utc(daily_data[0].METER_ENDTS).add(-1, "days").format('DD-MM-YYYY'),
                                            "CONC_CONS": FloatCOR_VOLUME_M3 ? new Number(daily_data[0].COR_VOLUME_M3).toFixed(FloatCOR_VOLUME_M3).toString() : daily_data[0].COR_VOLUME_M3,
                                            "UNCONV_CONS": FloatUNC_VOLUME_M3 ? new Number(daily_data[0].UNC_VOLUME_M3).toFixed(FloatUNC_VOLUME_M3).toString() : daily_data[0].UNC_VOLUME_M3,
                                            "CONV_CONS_AL": 'NA',
                                            "UNCONV_CONS_AL": 'NA',
                                            "TMIN": 'NA',
                                            "TMAX": 'NA',
                                            "TAV": FloatAVG_TEMPERATURE ? new Number(daily_data[0].AVG_TEMPERATURE).toFixed(FloatAVG_TEMPERATURE).toString() : daily_data[0].AVG_TEMPERATURE,
                                            "PMIN": 'NA',
                                            "PMAX": 'NA',
                                            "PAV": FloatAVG_PRESSURE ? new Number(daily_data[0].AVG_PRESSURE).toFixed(FloatAVG_PRESSURE).toString() : daily_data[0].AVG_PRESSURE,
                                            "QUMIN": 'NA',
                                            "QUMAX": 'NA',
                                            "QUAV": FloatAVG_UNC_FLOW_RATE_M3_H ? new Number(daily_data[0].AVG_UNC_FLOW_RATE_M3_H).toFixed(FloatAVG_UNC_FLOW_RATE_M3_H).toString() : daily_data[0].AVG_UNC_FLOW_RATE_M3_H,
                                            "QCMIN": 'NA',
                                            "QCMAX": 'NA',
                                            "QCAV": FloatAVG_COR_FLOW_RATE_M3_H ? new Number(daily_data[0].AVG_COR_FLOW_RATE_M3_H).toFixed(FloatAVG_COR_FLOW_RATE_M3_H).toString() : daily_data[0].AVG_COR_FLOW_RATE_M3_H,
                                            // "UNCONVERTED_INDEX": data.report_type =='RTI1756899877'?findFloat('UNC_VOLUME_TOTAL_M3') ? hourly_data[0]&&hourly_data[0].UNC_VOLUME_TOTAL_M3!=null?new Number(hourly_data[0].UNC_VOLUME_TOTAL_M3).toFixed(findFloat('UNC_VOLUME_TOTAL_M3')).toString():'NA' :hourly_data[0]&& hourly_data[0].UNC_VOLUME_TOTAL_M3!=null?hourly_data[0].UNC_VOLUME_TOTAL_M3:'NA':'NA',
                                            // "CONVERTED_INDEX": data.report_type =='RTI1756899877'?findFloat('COR_VOLUME_TOTAL_M3') ? hourly_data[0]&&hourly_data[0].COR_VOLUME_TOTAL_M3!=null?new Number(hourly_data[0].COR_VOLUME_TOTAL_M3).toFixed(findFloat('COR_VOLUME_TOTAL_M3')).toString():'NA' : hourly_data[0]&&hourly_data[0].COR_VOLUME_TOTAL_M3!=null?hourly_data[0].COR_VOLUME_TOTAL_M3:'NA':'NA',
                                            "UNCONVERTED_INDEX":'NA',
                                            "CONVERTED_INDEX":'NA',
                                            "UNCONVERTED_COUNTER": 'NA',
                                            "CONVERTED_COUNTER": 'NA',
                                            "METROL_ALARM": 'NA',
                                            "TEMPERATURE_ALARM": 'NA',
                                            "PRESSURE_ALARM": 'NA'
                                        })
                                    
                                    } else {
                                      
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                        
                                    }

                                }else if (props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1665810585135') {
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_STARTTS).format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]=FloatREADING_RECORD ? (new Number(daily_data[0].READING_RECORD) * props?.GSMdata?.CONVERSION_FACTOR || 1).toFixed(FloatREADING_RECORD).toString() : (daily_data[0].READING_RECORD * (props?.GSMdata?.CONVERSION_FACTOR || 1))
                                             
                                           }else{
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            "Date": moment.utc(daily_data[0].METER_STARTTS).format('DD-MM-YYYY'),
                                            "CONC_CONS": FloatREADING_RECORD ? (new Number(daily_data[0].READING_RECORD)*GSMdata?.CONVERSION_FACTOR||1).toFixed(FloatREADING_RECORD).toString() : daily_data[0].READING_RECORD,
                                            "UNCONV_CONS": FloatREADING_RECORD ? new Number(daily_data[0].READING_RECORD).toFixed(FloatREADING_RECORD).toString() : daily_data[0].READING_RECORD,
                                            "CONV_CONS_AL": 'NA',
                                            "UNCONV_CONS_AL": 'NA',
                                            "TMIN": 'NA',
                                            "TMAX": 'NA',
                                            "TAV": 'NA',
                                            "PMIN": 'NA',
                                            "PMAX": 'NA',
                                            "PAV": 'NA',
                                            "QUMIN": 'NA',
                                            "QUMAX": 'NA',
                                            "QUAV": 'NA',
                                            "QCMIN": 'NA',
                                            "QCMAX": 'NA',
                                            "QCAV": 'NA',
                                            "UNCONVERTED_INDEX": FloatPULSE_END_FINAL_COUNT ? new Number(daily_data[0].PULSE_END_FINAL_COUNT).toFixed(FloatPULSE_END_FINAL_COUNT).toString() : daily_data[0].PULSE_END_FINAL_COUNT,
                                            "CONVERTED_INDEX": 'NA',
                                            "UNCONVERTED_COUNTER": 'NA',
                                            "CONVERTED_COUNTER": 'NA',
                                            "METROL_ALARM": 'NA',
                                            "TEMPERATURE_ALARM": 'NA',
                                            "PRESSURE_ALARM": 'NA'
                                        })
                                    }

                                    } else {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]='Data Not Available'
                                        }else{
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                        }
                                    }
                                } 
                                 else if (props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1686575340512') {
                                    if(ID!=(filter_date.length-1)){
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_ENDTS).format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            CATEGORY0: props?.GSMdata.ACCOUNT_NAME,
                                            CATEGORY1: props?.GSMdata.GA_NAME,
                                            CATEGORY3: props?.GSMdata.DIVISION_NAME,
                                            Customer_Id: props?.GSMdata.CUSTOMER_ID,
                                            Business_Partner_Number: props?.GSMdata.BUSINESS_PARTNER_NUMBER,
                                            Date_of_intsalltion: props?.GSMdata.INSTALLATION_DETAILS.Installation_Date_And_Time.split(' ')[0],
                                            EVC_Profile:props?.GSMdata.METER_TYPE_NAME,
                                            Mechanical_Profile:props?.GSMdata.MECHANICAL_PROFILE_NAME,
                                            Mechanical_MESNO:props?.GSMdata.ASMETERSERNUM,
                                            "Date": moment.utc(daily_data[0].METER_ENDTS).format('DD-MM-YYYY'),
                                            "CONC_CONS": findFloat('VB') ? (new Number(daily_data[0].VB_DIFF)).toFixed(findFloat('VB')).toString() : (daily_data[0].VB_DIFF),
                                            "UNCONV_CONS": findFloat('VMT') ? (new Number(daily_data[0].VMT_DIFF)).toFixed(findFloat('VMT')).toString() : (daily_data[0].VMT_DIFF),
                                            "CONV_CONS_AL": 'NA',
                                            "UNCONV_CONS_AL": 'NA',
                                            "TMIN": 'NA',
                                            "TMAX": 'NA',
                                            "TAV": findFloat('T_DY') ? (new Number(daily_data[0].T_DY)).toFixed(findFloat('T_DY')).toString() : (daily_data[0].T_DY),
                                            "PMIN": 'NA',
                                            "PMAX": 'NA',
                                            "PAV": findFloat('P_DY') ? (new Number(daily_data[0].P_DY)).toFixed(findFloat('P_DY')).toString() : (daily_data[0].P_DY),
                                            "QUMIN": 'NA',
                                            "QUMAX": 'NA',
                                            "QUAV": 'NA',
                                            "QCMIN": 'NA',
                                            "QCMAX": 'NA',
                                            "QCAV": 'NA',
                                            "UNCONVERTED_INDEX": findFloat('VMT') ? (new Number(daily_data[0].VMT)).toFixed(findFloat('VMT')).toString() : (daily_data[0].VMT),
                                            "CONVERTED_INDEX": findFloat('VB') ? (new Number(daily_data[0].VB)).toFixed(findFloat('VB')).toString() : (daily_data[0].VB),
                                            "UNCONVERTED_COUNTER": 'NA',
                                            "CONVERTED_COUNTER": 'NA',
                                            "METROL_ALARM": 'NA',
                                            "TEMPERATURE_ALARM": 'NA',
                                            "PRESSURE_ALARM": 'NA'
                                        })
                                    

                                    } else {
                                      
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                        
                                    }
                                }
                                }else if(props?.GSMdata?.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1714730047327'){
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_ENDTS).format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]= findFloat('DVB') ? (new Number(daily_data[0].DVB)).toFixed(findFloat('DVB')).toString() : (daily_data[0].VB)
                                         
                                        }
                                    }else{
                                        if(TypeReport == 'RTI1716618289'){
                                            result_obj[moment(item,'YYYY-MM-DD').add(1, "days").format('DD-MM-YYYY')]='Data Not Available'
                                        }
                                    }
                                }
                                else {
                                    const daily_data = sapInfo.filter((sapdata) => (moment.utc(sapdata.METER_STARTTS).format('YYYY-MM-DD') == item))
                                    if (daily_data.length > 0) {
                                        
                                        arrayofData.push({
                                            "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                            "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                            "Date": moment.utc(daily_data[0].METER_STARTTS).format('DD-MM-YYYY'),
                                            CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            CATEGORY0: props?.GSMdata.ACCOUNT_NAME,
                                            CATEGORY1: props?.GSMdata.GA_NAME,
                                            CATEGORY3: props?.GSMdata.DIVISION_NAME,
                                            Customer_Id: props?.GSMdata.CUSTOMER_ID,
                                            Business_Partner_Number: props?.GSMdata.BUSINESS_PARTNER_NUMBER,
                                            Date_of_intsalltion: props?.GSMdata.INSTALLATION_DETAILS.Installation_Date_And_Time.split(' ')[0],
                                            EVC_Profile:props?.GSMdata.METER_TYPE_NAME,
                                            Mechanical_Profile:props?.GSMdata.MECHANICAL_PROFILE_NAME,
                                            Mechanical_MESNO:props?.GSMdata.ASMETERSERNUM,
                                            "CONC_CONS": FloatCONC_CONS ? new Number(daily_data[0].CONC_CONS).toFixed(FloatCONC_CONS).toString() : daily_data[0].CONC_CONS,
                                            "UNCONV_CONS": FloatUNCONV_CONS ? new Number(daily_data[0].UNCONV_CONS).toFixed(FloatUNCONV_CONS).toString() : daily_data[0].UNCONV_CONS,
                                            "CONV_CONS_AL": FloatCONV_CONS_AL ? new Number(daily_data[0].CONV_CONS_AL).toFixed(FloatCONV_CONS_AL).toString() : daily_data[0].CONV_CONS_AL,
                                            "UNCONV_CONS_AL": FloatUNCONV_CONS_AL ? new Number(daily_data[0].UNCONV_CONS_AL).toFixed(FloatUNCONV_CONS_AL).toString() : daily_data[0].UNCONV_CONS_AL,
                                            "TMIN": FloatTMIN ? new Number(daily_data[0].TMIN).toFixed(FloatTMIN).toString() : daily_data[0].TMIN,
                                            "TMAX": FloatTMAX ? new Number(daily_data[0].TMAX).toFixed(FloatTMAX).toString() : daily_data[0].TMAX,
                                            "TAV": FloatTAV ? new Number(daily_data[0].TAV).toFixed(FloatTAV).toString() : daily_data[0].TAV,
                                            "PMIN": FloatPMIN ? new Number(daily_data[0].PMIN).toFixed(FloatPMIN).toString() : daily_data[0].PMIN,
                                            "PMAX": FloatPMAX ? new Number(daily_data[0].PMAX).toFixed(FloatPMAX).toString() : daily_data[0].PMAX,
                                            "PAV": FloatPAV ? new Number(daily_data[0].PAV).toFixed(FloatPAV).toString() : daily_data[0].PAV,
                                            "QUMIN": FloatQUMIN ? new Number(daily_data[0].QUMIN).toFixed(FloatQUMIN).toString() : daily_data[0].QUMIN,
                                            "QUMAX": FloatQUMAX ? new Number(daily_data[0].QUMAX).toFixed(FloatQUMAX).toString() : daily_data[0].QUMAX,
                                            "QUAV": FloatQUAV ? new Number(daily_data[0].QUAV).toFixed(FloatQUAV).toString() : daily_data[0].QUAV,
                                            "QCMIN": FloatQCMIN ? new Number(daily_data[0].QCMIN).toFixed(FloatQCMIN).toString() : daily_data[0].QCMIN,
                                            "QCMAX": FloatQCMAX ? new Number(daily_data[0].QCMAX).toFixed(FloatQCMAX).toString() : daily_data[0].QCMAX,
                                            "QCAV": FloatQCAV ? new Number(daily_data[0].QCAV).toFixed(FloatQCAV).toString() : daily_data[0].QCAV,
                                            "UNCONVERTED_INDEX": FloatUNCONVERTED_INDEX ? new Number(daily_data[0].UNCONVERTED_INDEX).toFixed(FloatUNCONVERTED_INDEX).toString() : daily_data[0].UNCONVERTED_INDEX,
                                            "CONVERTED_INDEX": FloatCONVERTED_INDEX ? new Number(daily_data[0].CONVERTED_INDEX).toFixed(FloatCONVERTED_INDEX).toString() : daily_data[0].CONVERTED_INDEX,
                                            "UNCONVERTED_COUNTER": FloatUNCONVERTED_COUNTER ? new Number(daily_data[0].UNCONVERTED_COUNTER).toFixed(FloatUNCONVERTED_COUNTER).toString() : daily_data[0].UNCONVERTED_COUNTER,
                                            "CONVERTED_COUNTER": FloatCONVERTED_COUNTER ? new Number(daily_data[0].CONVERTED_COUNTER).toFixed(FloatCONVERTED_COUNTER).toString() : daily_data[0].CONVERTED_COUNTER,
                                            "METROL_ALARM": FloatMETROL_ALARM ? new Number(daily_data[0].METROL_ALARM).toFixed(FloatMETROL_ALARM).toString() : daily_data[0].METROL_ALARM,
                                            "TEMPERATURE_ALARM": FloatTEMPERATURE_ALARM ? new Number(daily_data[0].TEMPERATURE_ALARM).toFixed(FloatTEMPERATURE_ALARM).toString() : daily_data[0].TEMPERATURE_ALARM,
                                            "PRESSURE_ALARM": FloatPRESSURE_ALARM ? new Number(daily_data[0].PRESSURE_ALARM).toFixed(FloatPRESSURE_ALARM).toString() : daily_data[0].PRESSURE_ALARM
                                        })
                                    
                                    } else {
                                        
                                        arrayofData.push({ ...statisc_obj, 'Date': moment(item, 'YYYY-MM-DD').format('DD-MM-YYYY') })
                                        
                                    }
                                }
                            });
                        if (sapInfo.length > 0) {
                            arrayofData.push(result_obj)
                        } else {
                            filter_date.map((date) => {
                                arrayofData.push({
                                    "address_compony_name": props?.GSMdata?.ADDRESS?.address_compony_name,
                                    "INSTALLATION_NUMBER": props?.GSMdata.INSTALLATION_NUMBER,
                                    "Date": moment.utc(date).format('DD-MM-YYYY'),
                                    CATEGORY2_NAME: props?.GSMdata.CIRCLE_NAME,
                                            CATEGORY0: props?.GSMdata.ACCOUNT_NAME,
                                            CATEGORY1: props?.GSMdata.GA_NAME,
                                            CATEGORY3: props?.GSMdata.DIVISION_NAME,
                                            Customer_Id: props?.GSMdata.CUSTOMER_ID,
                                            Business_Partner_Number: props?.GSMdata.BUSINESS_PARTNER_NUMBER,
                                            Date_of_intsalltion: props?.GSMdata.INSTALLATION_DETAILS.Installation_Date_And_Time.split(' ')[0],
                                            EVC_Profile:props?.GSMdata.METER_TYPE_NAME,
                                            Mechanical_Profile:props?.GSMdata.MECHANICAL_PROFILE_NAME,
                                            Mechanical_MESNO:props?.GSMdata.ASMETERSERNUM,
                                    "CONC_CONS": 'Data Not Available',
                                    "UNCONV_CONS": 'Data Not Available',
                                    "CONV_CONS_AL": 'Data Not Available',
                                    "UNCONV_CONS_AL": 'Data Not Available',
                                    "TMIN": 'Data Not Available',
                                    "TMAX": 'Data Not Available',
                                    "TAV": 'Data Not Available',
                                    "PMIN": 'Data Not Available',
                                    "PMAX": 'Data Not Available',
                                    "PAV": 'Data Not Available',
                                    "QUMIN": 'Data Not Available',
                                    "QUMAX": 'Data Not Available',
                                    "QUAV": 'Data Not Available',
                                    "QCMIN": 'Data Not Available',
                                    "QCMAX": 'Data Not Available',
                                    "QCAV": 'Data Not Available',
                                    "UNCONVERTED_INDEX": 'Data Not Available',
                                    "CONVERTED_INDEX": 'Data Not Available',
                                    "UNCONVERTED_COUNTER": 'Data Not Available',
                                    "CONVERTED_COUNTER": 'Data Not Available',
                                    "METROL_ALARM": 'Data Not Available',
                                    "TEMPERATURE_ALARM": 'Data Not Available',
                                    "PRESSURE_ALARM": 'Data Not Available'
                                })
                            
                            })
                        }

                        if (reportType === 'csv') {
                            if (report?.value != 'Full_Table') {
                                exportCSVFile(headers, arrayofData, `dailydata_` + '(' + report.start_date + ')' + '_' + '(' + report.end_date + ')' + `_${new Date().getTime()}`)
                            } else {
                                exportCSVFile(headers, arrayofData, `dailydata_${new Date().getTime()}`)

                            }
                            setReportLoader(false)

                        } else {
                            console.log(arrayofData);
                            if (report?.value != 'Full_Table') {
                                exportExcelDownload(arrayofData, `dailydata_` + '(' + report.start_date + ')' + '_' + '(' + report.end_date + ')' + `_${new Date().getTime()}`)
                            } else {
                                exportExcelDownload(arrayofData, `dailydata_${new Date().getTime()}`)

                            }
                            setReportLoader(false)

                        }
                    }else{
                        toast.warn('script Decode Id Not Found')
                        setReportLoader(false)
                    }
                } else {
                    data.data.data.map((item, id) => {

                        if (TypeReport == 'Consumption-Report-UICO-Type') {
                            if (id == 0) {
                                headers = {
                                    "Installation Number": "Installation Number",
                                    "Device No": "Device No",
                                    "Register": "Register",
                                    "Reading/Consumption": "Reading/Consumption",
                                    "Time": "Time",
                                    "Schduled Meter Reading Date": "Schduled Meter Reading Date",
                                    "Time": "Time",
                                    "Meter Reader": "Meter Reader",
                                    "Notes": "Notes",
                                }
                            }
                            if (props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1665810585135' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1676094508509' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1676094601754' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1676094645995') {
                                const obj = {
                                    "Installation Number": props?.GSMdata?.INSTALLATION_NUMBER,
                                    "Device No": props?.GSMdata?.ASMETERSERNUM,
                                    "Register": "001",
                                    "Reading/Consumption":
                                        item.PULSE_END_FINAL_COUNT,
                                    "Meter Reading Date": item.METER_STARTTS
                                        ? moment(item.METER_STARTTS).format(
                                            "DD.MM.YYYY"
                                        )
                                        : '',
                                    "Time": "0600",
                                    "Schduled Meter Reading Date": moment(
                                        item.METER_ENDTS
                                    ).format("DD.MM.YYYY"),
                                    "Time": "0600",
                                    "Meter Reader": "",
                                    "Notes": "",
                                };
                                arrayofData.push(obj)

                            } if (props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1680324563157'||props?.GSMdata.SCRIPTDECODE_ID =='MODUID1655982881372_METEID1684236463648') {
                                var FloatValue = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'COR_VOLUME_M3').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'COR_VOLUME_M3')[0].Float : ''
                                const obj = {
                                    "Installation Number": props?.GSMdata?.INSTALLATION_NUMBER,
                                    "Device No": item.CONVSERNUM.trim(),
                                    "Register": "001",
                                    "Reading/Consumption":
                                        FloatValue ? new Number(
                                            item.COR_VOLUME_M3
                                        )
                                            .toFixed(FloatValue)
                                            .toString() : item.COR_VOLUME_M3,
                                    "Meter Reading Date": item.METER_STARTTS
                                        ? moment(item.METER_STARTTS).format(
                                            "DD.MM.YYYY"
                                        )
                                        : moment(item.METER_ENDTS)
                                            .add(-1, "days")
                                            .format("DD.MM.YYYY"),
                                    "Time": "0600",
                                    "Schduled Meter Reading Date": moment(
                                        item.METER_ENDTS
                                    ).format("DD.MM.YYYY"),
                                    "Time": "0600",
                                    "Meter Reader": "",
                                    "Notes": "",
                                };
                                arrayofData.push(obj)

                            } else {

                                var FloatValue = ''
                                var FloatValue1 = ''

                                var CONVERTED_INDEX;
                                var UNCONVERTED_INDEX;
                                if (

                                    props?.GSMdata?.SCRIPTDECODE_ID ==
                                    "MODUID1655982881372_METUID1662788927078" ||
                                    props?.GSMdata?.SCRIPTDECODE_ID == "MODUID1655982881372_METEID1676466785467"
                                ) {
                                    FloatValue = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM')[0].Float : ''
                                    FloatValue1 = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB')[0].Float : ''

                                    CONVERTED_INDEX = item.VB
                                    UNCONVERTED_INDEX = item.VM
                                } else if (props?.GSMdata?.SCRIPTDECODE_ID ==
                                    "MODUID1655982881372_METUID1660276730566") {
                                    FloatValue = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM')[0].Float : ''
                                    FloatValue1 = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVB_HC').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'ST_DVB_HC')[0].Float : ''

                                    CONVERTED_INDEX = item.ST_DVB_HC
                                    UNCONVERTED_INDEX = item.VM
                                } else if (props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660278946950' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660279003632' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1675315671021'||props?.GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1681294010406'||props?.GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1712827870326'|| props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1680786811818') {
                                    // console.log(props?.GSMdata.SCRIPTDECODE_ID)
                                    FloatValue = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRIMARY_VOLUME_VM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRIMARY_VOLUME_VM')[0].Float : ''
                                    FloatValue1 = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB_DIFF_DAILY_VBD_D').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB_DIFF_DAILY_VBD_D')[0].Float : ''

                                    CONVERTED_INDEX = item.VB_DIFF_DAILY_VBD_D
                                    UNCONVERTED_INDEX = item.PRIMARY_VOLUME_VM
                                } else {
                                    FloatValue = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONVERTED_INDEX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONVERTED_INDEX')[0].Float : ''
                                    FloatValue1 = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONV_CONS_AL').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONV_CONS_AL')[0].Float : ''


                                    CONVERTED_INDEX = item.CONV_CONS_AL
                                    UNCONVERTED_INDEX = item.UNCONVERTED_INDEX
                                }

                                const obj = {
                                    "Installation Number": props?.GSMdata?.INSTALLATION_NUMBER,
                                    "Device No": item.ASMETERSERNUM.trim(),
                                    "Register": "001",
                                    "Reading/Consumption": FloatValue ? new Number(
                                        UNCONVERTED_INDEX
                                    )
                                        .toFixed(FloatValue)
                                        .toString() : UNCONVERTED_INDEX,
                                    "Meter Reading Date": item.METER_STARTTS
                                        ? moment(item.METER_STARTTS).format(
                                            "DD.MM.YYYY"
                                        )
                                        : moment(item.METER_ENDTS)
                                            .add(-1, "days")
                                            .format("DD.MM.YYYY"),
                                    "Time": "0600",
                                    Schduled_Meter_Reading_Date: moment(
                                        item.METER_ENDTS
                                    ).format("DD.MM.YYYY"),
                                    Time: "0600",
                                    "Meter Reader": "",
                                    Notes: "",
                                };
                                const obj2 = {
                                    "Installation Number": props?.GSMdata?.INSTALLATION_NUMBER,
                                    "Device No": item.CONVSERNUM.trim(),
                                    "Register": "001",
                                    "Reading/Consumption": FloatValue1 ? new Number(CONVERTED_INDEX)
                                        .toFixed(FloatValue1)
                                        .toString() : CONVERTED_INDEX,
                                    "Meter Reading Date": item.METER_STARTTS
                                        ? moment(item.METER_STARTTS).format(
                                            "DD.MM.YYYY"
                                        )
                                        : moment(item.METER_ENDTS)
                                            .add(-1, "days")
                                            .format("DD.MM.YYYY"),
                                    "Time": "0600",
                                    Schduled_Meter_Reading_Date: moment(
                                        item.METER_ENDTS
                                    ).format("DD.MM.YYYY"),
                                    Time: "0600",
                                    "Meter Reader": "",
                                    Notes: "",
                                };
                                arrayofData.push(obj)
                                arrayofData.push(obj2)

                            }
                        } else if (TypeReport == 'Index-Report-UICI-Type') {
                            if (id == 0) {
                                headers = {
                                    "Installation Number": "Installation Number",
                                    "Device No": "Device No",
                                    "Register": "Register",
                                    "Reading/Consumption": "Reading/Consumption",
                                    "Time": "Time",
                                    "Schduled Meter Reading Date": "Schduled Meter Reading Date",
                                    "Time": "Time",
                                    "Meter Reader": "Meter Reader",
                                    "Notes": "Notes",
                                }
                            }
                            var FloatValue = ''
                            var FloatValue1 = ''
                            if (props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1665810585135' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1676094508509' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1676094601754' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1671705090942_METUID1676094645995') {
                                const obj = {
                                    "Installation Number": props?.GSMdata?.INSTALLATION_NUMBER,
                                    "Device No": props?.GSMdata?.ASMETERSERNUM,
                                    "Register": "001",
                                    "Reading/Consumption":
                                        item.PULSE_END_FINAL_COUNT,
                                    "Meter Reading Date": item.METER_STARTTS
                                        ? moment(item.METER_STARTTS).format(
                                            "DD.MM.YYYY"
                                        )
                                        : '',
                                    "Time": "0600",
                                    "Schduled Meter Reading Date": moment(
                                        item.METER_ENDTS
                                    ).format("DD.MM.YYYY"),
                                    "Time": "0600",
                                    "Meter Reader": "",
                                    "Notes": "",
                                };
                                arrayofData.push(obj)

                            } else {
                                var CONVERTED_INDEX;
                                var UNCONVERTED_INDEX;
                                if (
                                    props?.GSMdata?.SCRIPTDECODE_ID ==
                                    "MODUID1655982881372_METUID1660276730566" ||
                                    props?.GSMdata?.SCRIPTDECODE_ID ==
                                    "MODUID1655982881372_METUID1662788927078" ||
                                    props?.GSMdata?.SCRIPTDECODE_ID == "MODUID1655982881372_METEID1676466785467"
                                ) {
                                    FloatValue = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VM')[0].Float : ''
                                    FloatValue1 = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'VB')[0].Float : ''


                                    CONVERTED_INDEX = item.VB;
                                    UNCONVERTED_INDEX = item.VM;
                                } else if (props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660278946950' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1660279003632' || props?.GSMdata?.SCRIPTDECODE_ID == 'MODUID1655982881372_METUID1675315671021'||props?.GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1681294010406'||props?.GSMdata.SCRIPTDECODE_ID=='MODUID1655982881372_METEID1712827870326'|| props?.GSMdata.SCRIPTDECODE_ID == 'MODUID1655982881372_METEID1680786811818') {
                                    FloatValue = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRIMARY_VOLUME_VM').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'PRIMARY_VOLUME_VM')[0].Float : ''
                                    FloatValue1 = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'BASE_VOLUME_VB').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'BASE_VOLUME_VB')[0].Float : ''

                                    CONVERTED_INDEX = item.BASE_VOLUME_VB
                                    UNCONVERTED_INDEX = item.PRIMARY_VOLUME_VM
                                } else {
                                    FloatValue = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONVERTED_INDEX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'UNCONVERTED_INDEX')[0].Float : ''
                                    FloatValue1 = ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONVERTED_INDEX').length > 0 ? ColumnName.Meter_Data.dailydata.filter(into => into.Column == 'CONVERTED_INDEX')[0].Float : ''

                                    CONVERTED_INDEX = item.CONVERTED_INDEX;
                                    UNCONVERTED_INDEX = item.UNCONVERTED_INDEX;
                                }

                                const obj = {
                                    "Installation Number": props?.GSMdata?.INSTALLATION_NUMBER,
                                    "Device No": item.ASMETERSERNUM.trim(),
                                    "Register": "001",
                                    "Reading/Consumption": FloatValue ? new Number(UNCONVERTED_INDEX)
                                        .toFixed(FloatValue)
                                        .toString() : UNCONVERTED_INDEX,
                                    "Meter Reading Date": item.METER_STARTTS
                                        ? moment(item.METER_STARTTS).format(
                                            "DD.MM.YYYY"
                                        )
                                        : moment(item.METER_ENDTS)
                                            .add(-1, "days")
                                            .format("DD.MM.YYYY"),
                                    "Time": "0600",
                                    "Schduled Meter Reading Date": moment(
                                        item.METER_ENDTS
                                    ).format("DD.MM.YYYY"),
                                    "Time": "0600",
                                    "Meter Reader": "",
                                    "Notes": "",
                                };
                                const obj2 = {
                                    "Installation Number": props?.GSMdata?.INSTALLATION_NUMBER,
                                    "Device No": item.CONVSERNUM.trim(),
                                    "Register": "001",
                                    "Reading/Consumption": FloatValue1 ? new Number(CONVERTED_INDEX)
                                        .toFixed(FloatValue1)
                                        .toString() : CONVERTED_INDEX,
                                    "Meter Reading Date": item.METER_STARTTS
                                        ? moment(item.METER_STARTTS).format(
                                            "DD.MM.YYYY"
                                        )
                                        : moment(item.METER_ENDTS)
                                            .add(-1, "days")
                                            .format("DD.MM.YYYY"),
                                    "Time": "0600",
                                    "Schduled Meter Reading Date": moment(
                                        item.METER_ENDTS
                                    ).format("DD.MM.YYYY"),
                                    "Time": "0600",
                                    "Meter Reader": "",
                                    "Notes": "",
                                };

                                arrayofData.push(obj)
                                arrayofData.push(obj2)

                            }
                        } else {
                            arrayofData[id] = {}
                            ColumnName?.Meter_Data?.dailydata?.length > 0 && ColumnName?.Meter_Data?.dailydata?.map((col) => {
                                if (TypeReport == 'View_Table_Info') {
                                    if (col?.Display == "Yes") {
                                        if(id==0){
                                            if(col?.CF==true){
                                                headers[col.Header]=col.Header+' With CF'
                                            }else{
                                            headers[col.Header]=col.Header
                                            }
                                        }
                                        if(GSMdata?.SCRIPTDECODE_ID==='MODUID1671705090942_METUID1665810585135'&&col.Column=='READING_RECORD'){
                                            arrayofData[id]["Conv. cons."]=item[col.Column]*GSMdata?.CONVERSION_FACTOR
                                        }
                                        if (col?.Type == 'datetime' || col?.Column == 'MAX_CONC_CONS_DATE' || col?.Column == 'MAX_UNCONV_CONS_DATE') {
                                            if (col.Column == "METER_ENDTS" || col.Column == "METER_STARTTS" || col.Column == "METER_BCD_TS" || col.Column == "METER_TS") {
                                                if (col.Column == "METER_BCD_TS" || col.Column == "METER_TS") {
                                                    arrayofData[id]["Date"] = item[col.Column] ? moment.utc(item[col.Column]).format("DD-MM-YYYY") : ''
                                                    arrayofData[id]["Time"] = item[col.Column] ? moment.utc(item[col.Column]).format("HH:mm:ss") : ''
                                                } else {
                                                    if (col.Column == "METER_ENDTS") {
                                                        arrayofData[id]["Date"] = item[col.Column] ? moment.utc(item[col.Column]).format("DD-MM-YYYY") : ''
                                                        arrayofData[id]["Time"] = item[col.Column] ? moment.utc(item[col.Column]).format("HH:mm:ss") : ''
                                                    } else {
                                                        arrayofData[id]["Date"] = item[col.Column] ? moment.utc(item[col.Column]).format("DD-MM-YYYY") : ''
                                                        arrayofData[id]["Time"] = item[col.Column] ? moment.utc(item[col.Column]).format("HH:mm:ss") : ''
                                                    }
                                                }
                                            } else {
                                                arrayofData[id][col.Header] = item[col.Column] ? moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A") : ''
                                            }
                                        } else {
                                            if(col?.Float!=null&&col?.Float!=undefined){
                                                if(col?.CF==true){
                                                    arrayofData[id][col.Header]=(Number(item[col.Column])*GSMdata?.CONVERSION_FACTOR).toFixed(col?.Float)
                                                }else{
                                                arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
                                                }
                                              }else{
                                                if(col?.CF==true){
                                              arrayofData[id][col.Header]=item[col.Column]*GSMdata?.CONVERSION_FACTOR
                                                }else{
                                                    arrayofData[id][col.Header]=item[col.Column]
                            
                                                }
                                              }
                                        }
                                    
                                    }
                                } else if (report?.value == 'All_Table_Info' || report?.value == 'Full_Table') {
                                    if(id==0){
                                        if(col?.CF==true){
                                            headers[col.Header]=col.Header+' With CF'
                                        }else{
                                        headers[col.Header]=col.Header
                                        }
                                    }
                                    if(GSMdata?.SCRIPTDECODE_ID==='MODUID1671705090942_METUID1665810585135'&&col.Column=='READING_RECORD'){
                                        arrayofData[id]["Conv. cons."]=item[col.Column]*GSMdata?.CONVERSION_FACTOR
                                    }
                                    if (col?.Type == 'datetime' || col?.Column == 'MAX_CONC_CONS_DATE' || col?.Column == 'MAX_UNCONV_CONS_DATE') {
                                        if (col.Column == "METER_ENDTS" || col.Column == "METER_STARTTS" || col.Column == "METER_BCD_TS" || col.Column == "METER_TS") {
                                            if (col.Column == "METER_BCD_TS" || col.Column == "METER_TS") {
                                                arrayofData[id]["Date"] = item[col.Column] ? moment.utc(item[col.Column]).format("DD-MM-YYYY") : ''
                                                arrayofData[id]["Time"] = item[col.Column] ? moment.utc(item[col.Column]).format("HH:mm:ss") : ''
                                            } else {
                                                if (col.Column == "METER_ENDTS") {
                                                    arrayofData[id]["Date"] = item[col.Column] ? moment.utc(item[col.Column]).format("DD-MM-YYYY") : ''
                                                    arrayofData[id]["Time"] = item[col.Column] ? moment.utc(item[col.Column]).format("HH:mm:ss") : ''
                                                } else {
                                                    arrayofData[id]["Date"] = item[col.Column] ? moment.utc(item[col.Column]).format("DD-MM-YYYY") : ''
                                                    arrayofData[id]["Time"] = item[col.Column] ? moment.utc(item[col.Column]).format("HH:mm:ss") : ''
                                                }
                                            }
                                        } else {
                                            arrayofData[id][col.Header] = item[col.Column] ? moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A") : ''
                                        }
                                    } else {
                                        if(col?.Float!=null&&col?.Float!=undefined){
                                            if(col?.CF==true){
                                                arrayofData[id][col.Header]=(Number(item[col.Column])*GSMdata?.CONVERSION_FACTOR).toFixed(col?.Float)
                                            }else{
                                            arrayofData[id][col.Header]=Number(item[col.Column]).toFixed(col?.Float)
                                            }
                                          }else{
                                            if(col?.CF==true){
                                          arrayofData[id][col.Header]=item[col.Column]*GSMdata?.CONVERSION_FACTOR
                                            }else{
                                                arrayofData[id][col.Header]=item[col.Column]
                        
                                            }
                                          }
                                    }
                                }

                            })
                        }
                        if (id == (data.data.data.length - 1)) {
                            if (reportType === 'csv') {
                                if (report?.value != 'Full_Table') {
                                    exportCSVFile(headers, arrayofData, `dailydata_` + '(' + report.start_date + ')' + '_' + '(' + report.end_date + ')' + `_${new Date().getTime()}`)
                                } else {
                                    exportCSVFile(headers, arrayofData, `dailydata_${new Date().getTime()}`)

                                }
                                setReportLoader(false)

                            } else {
                                console.log(arrayofData);
                                if (report?.value != 'Full_Table') {
                                    exportExcelDownload(arrayofData, `dailydata_` + '(' + report.start_date + ')' + '_' + '(' + report.end_date + ')' + `_${new Date().getTime()}`)
                                } else {
                                    exportExcelDownload(arrayofData, `dailydata_${new Date().getTime()}`)

                                }
                                setReportLoader(false)

                            }
                        }
                    })
                }

            } else {
                console.log('something want wrong')
                setReportLoader(false)

            }
        }
    }

    const [BufferPageSize, setBufferPageSize] = useState(50)
    const [BuffernumberofPage, setBuffernumberofPage] = useState(0)
    const [BuffercurrentPage, setBufferCurrentPage] = useState(1);
    const [BufferPenddingData, setBufferPenddingData] = useState([])
    const [modalBufferDetails, setmodalBufferDetails] = useState(false)

    const onBufferPenddingData = async (col) => {
        setmodelTitle(col)

        setmodalBufferDetails(true)
        const firstPageIndex = (BuffercurrentPage - 1) * BuffercurrentPage;

        const data = await getConfigretionCMD_TYPEWisePendding_Data_buffer({ CONSUMER_ID: props.GSMdata.UNIQUE_ID, CMD_TYPE: col, start: firstPageIndex, end: BufferPageSize, tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.gsmbuffer })
        if (data.success === true) {
            setBuffernumberofPage(data.data.Total)
            setBufferPenddingData(data.data.data)
        } else {
            console.log('something want wrong')

        }
    }

    useEffect(() => {
        if (BufferPenddingData.length > 0) {
            onBufferPenddingData()
        }
    }, [BuffercurrentPage, BufferPageSize])

    const onSort = (field) => {
        if (sortField === field) {
            setsortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setsortField(field);
            setsortDirection('asc');
        }
    };

    useEffect(() => {
        ongetHeardbeatData()
    }, [sortDirection, sortField])
    return (
        <>

            <Modal title="Generate Reports" visible={ismodel} footer='' onCancel={() => setModel(false)} width={1500}>
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-row" style={{ marginLeft: "10px" }}>
                                    <div className="form-group col-md-3" id="grpeui">
                                        <label for="inputPassword4 ">Report Type :<span className='text-danger'>*</span> </label>
                                        <select className="form-control form-select" name="reporttype" required="required" onChange={onHandalReport}>
                                            <option value="">Report Type</option>
                                            {ReportType?.map((item) => (<option value={item?.value}>{item?.label}</option>))}
                                        </select>
                                    </div>
                                    {report?.value == 'Daily-Consumption-Report' ?
                                        <><div className="form-group col-md-3" id="strdate">
                                            <label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
                                            <DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
                                                disabledDate={(current) => {
                                                    return (moment.utc(ExpireDate.start_date).add(-1,'days').add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m") <= current);
                                                }} value={report.start_date?moment(report.start_date,'DD-MM-YYYY'):''}/>
                                        </div>
                                            <div className="form-group col-md-3" id="enddate" >
                                                <label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
                                                <DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
                                                    disabledDate={(current) => {
                                                        return (moment(ExpireDate.start_date) >= current) || (moment(ExpireDate.end_date) <= current);
                                                    }} value={report.end_date?moment(report.end_date,'DD-MM-YYYY'):''}/>
                                            </div></>
                                        :report?.value == 'RTI1715060750'||report?.value == 'RTI1715060841'?<><div className="form-group col-md-3" id="strdate">
                                        <label for="inputPassword4">Select Month :<span className='text-danger'>*</span> </label><br></br>
                                        <DatePicker picker="month" onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
                                            disabledDate={(current) => {
                                                return moment(current.format('MM-YYYY'),'MM-YYYY').isAfter(moment()) 
                                            }}  value={report.start_date?moment(report.start_date,'MM-YYYY '):''}/>
                                    </div>
                                        </>: report?.value != '' && report?.value != 'Full_Table' ? <><div className="form-group col-md-3" id="strdate">
                                            <label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
                                            <DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
                                                disabledDate={(current) => {
                                                    return (moment.utc(ExpireDate.start_date).add(-1,'days').add(-moment().utcOffset(), "m") >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
                                                }} value={report.start_date?moment(report.start_date,'DD-MM-YYYY'):''}/>
                                        </div>
                                            <div className="form-group col-md-3" id="enddate" >
                                                <label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
                                                <DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
                                                    disabledDate={(current) => {
                                                        return (moment(ExpireDate.start_date) >= current) || (moment(ExpireDate.end_date).add(1, 'days') <= current);
                                                    }} value={report.end_date?moment(report.end_date,'DD-MM-YYYY'):''}/>
                                            </div></> : ''}
                                    <div className="form-group col-md-3" id="grpeui">
                                        <label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
                                        <select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
                                            <option value="">Select Extension</option>
                                            <option value="xls">XLS</option>
                                            <option value="csv">CSV</option>
                                        </select>
                                    </div>
                                </div>
                                <p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
                                <div className="text-left">
                                    <input type="submit" name="submit" className={`btn btn-danger mr-2 ${ReportLoader == true ? 'disabled btn-progress' : ''}`} value="Generate" id="submitvn" onClick={ongetGenretedAllGSMReport} />
                                    <Link className="btn btn-light text-dark" onClick={() => setModel(false)}>Cancel</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Modal>
            <Modal title={modelTitle + '(' + (BuffernumberofPage ? BuffernumberofPage : 0) + ')'} visible={modalBufferDetails} className="my-modal-class" footer='' maskClosable={false} onCancel={() => (setmodalBufferDetails(false))} width={1500}>

                <div className="table-responsive mt-2">
                    <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                        <div class="dataTables_length ml-2" id="table_id_length">
                            <label>
                                Show{" "}
                                <select name="table_id_length " aria-controls="table_id" class="" onChange={(e) => (setBufferPageSize(e.target.value))} value={BufferPageSize}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>{" "}
                                entries
                            </label>
                        </div>

                        <table id="table_id" className="tableCustom table table-striped">
                            <thead>
                                <tr>
                                    <th className="text-center" >Unique No</th>
                                    {ColumnName?.GSM_BUFFER?.length > 0 && ColumnName?.GSM_BUFFER?.map((col) => (col?.Display == true ? <th className=""><BootstrapTooltip title={col?.Column}><span>{col?.Header}</span></BootstrapTooltip></th> : ''))}
                                </tr>
                            </thead>
                            <tbody>
                                {BufferPenddingData.length > 0 && BufferPenddingData.map((item, id) => (
                                    <tr className="trheigth" >
                                        {ColumnName?.GSM_BUFFER?.length > 0 && ColumnName?.GSM_BUFFER?.map((col) => (col?.Display == false ? '' : col?.DataType == 'DATETIME' ? <td style={{ minWidth: "100px" }}>{item[col.Column] ? moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A") : ''}</td> : col.Column == 'SCR_STATUS_CMD' ? <td>{item?.SCR_STATUS_CMD == 1 ? <div class="badge badge-success badge-shadow">Success</div> : item?.SCR_CNT_CMD_RETRY < 0 && item?.SCR_STATUS_CMD == 0 ? <div class="badge badge-warning badge-shadow">Waiting For Downlink</div> : item?.SCR_STATUS_CMD == 2 ? <div class="badge badge-info badge-shadow">Canceled By User</div> : item?.SCR_STATUS_CMD == -1 ? <div class="badge badge-danger badge-shadow">Timeout</div> : item?.SCR_CNT_CMD_RETRY >= 0 && item?.SCR_STATUS_CMD == 0 ? <div class="badge badge-primary text-white badge-shadow">Waiting For Uplink</div> : ''}</td> : col.Column == 'SCR_ID_CMD_ADDED_BY' ? <td>{item?.SCR_ID_CMD_ADDED_BY === 0 ? <div class="badge badge-warning badge-shadow">Script</div> : <div class="badge badge-success badge-shadow">User</div>}</td> : <td>{item[col.Column]}</td>))}

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <><div className='d-flex justify-content-between'>
                    <div>Total Number Of Row {BuffernumberofPage || 0}</div><Pagination
                        className="pagination-bar"
                        currentPage={BuffercurrentPage}
                        totalCount={BuffernumberofPage || 0}
                        pageSize={BufferPageSize}
                        onPageChange={(page) => setBufferCurrentPage(page)}
                    /></div></>
            </Modal>
            <Modal visible={modal} footer='' maskClosable={false} onCancel={() => (setModal(false))} width={500}>
                <h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">Getting {modelTitle} Command</h5>
                <h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!CommandStatus?.SCR_STATUS_CMD_ADD && CommandStatus?.SCR_STATUS_CMD_ADD != 'Added In Buffer' ? <img className='mr-2' src={clockPending} height='20px' width={'20px'}></img> : CommandStatus?.SCR_STATUS_CMD_ADD != 'Added In Buffer' ? <i className='fas fa-times mr-2' style={{ color: 'red' }}></i> : <i className='fas fa-check mr-2' style={{ color: 'green' }}></i>}{!CommandStatus?.SCR_STATUS_CMD_ADD ? 'Command Pending' : CommandStatus?.SCR_STATUS_CMD_ADD}</h5>
                <div class="">
                    {CommandStatus?.SCR_STATUS_CMD_ADD == 'Added In Buffer' ? <div class="text-center" style={{ marginTop: "10px", fontSize: "20px" }}>
                        <label style={{ fontWeight: "400" }}>Waiting For Respose</label>
                        <div id="meter_data_mqtt_timer_status" style={{ fontSize: "18px" }}>{`${zeroPad(time)}`} seconds left</div>
                    </div> : ''}
                </div>
                <div class="text-center" style={{ marginTop: "20px" }}>
                    <input type="button" class="btn btn-danger " value="Close" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={CloseModel} />
                </div>
            </Modal>
            <Modal visible={modalMsg} footer='' maskClosable={false} onCancel={() => (setmodalMsg(false))} width={500}>
                {MqttMsg}
            </Modal>
            <Modal title="" visible={Read1Msg} footer="" onCancel={() => setRead1Msg(false)} width={1000}>
                <div className="d-flex">
                    <Space direction="horizontal">
                        <div className="d-flex flex-column">Start In
                            <DatePicker onChange={onChange} showTime format='DD-MM-YYYY HH:mm:ss' disabledDate={(current) => {
                                return current.isAfter(moment())
                            }} name='start' /></div>
                        <div className="d-flex flex-column" >End In
                            <DatePicker onChange={onChanges} name='end' showTime format='DD-MM-YYYY HH:mm:ss' disabledDate={(current) => {
                                const start = moment(dates.start, 'DD-MM-YYYY HH:mm:ss')
                                return current.format('DD-MM-YYYY') < start.format('DD-MM-YYYY') || current.format('DD-MM-YYYY') > start.add(1, 'days').format('DD-MM-YYYY')
                            }} disabled={dates.start === '' ? true : false} /></div>
                    </Space>

                    <a className={`btn btn-danger text-white ml-3 mt-3 `} onClick={onSubmitCommand}>Submit</a>
                </div>
                <div className="text-danger mt-1">* Maximum Duration between Start and End :  2 Days Only</div>

            </Modal>
            <Modal title="" visible={Read2Msg} footer="" onCancel={() => setRead2Msg(false)} width={1000}>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="inputState">Index:</label>
                        <input type="text" class="form-control" id="" name="FirstName" placeholder="Enter Index" onChange={(e) => setIndex(e.target.value)} value={Index} />
                    </div>
                </div>
                <a className={`btn btn-danger text-white ml-3 `} onClick={onSubmitCommand}>Submit</a>

            </Modal>
            <Modal title="Error Data" visible={isModalError} footer='' onCancel={() => (setIsModalError(false))} width={1000}>
                {ErrorData}
            </Modal>
            <Modal title="Duplicate Data" visible={isModalDup} footer='' onCancel={() => (setIsModalDup(false))} width={1000}>
                {DupData}
            </Modal>
            <Modal title="Row Data" visible={isModalrow} footer='' onCancel={() => (setIsModalRow(false))} width={1000}>
                {rowData}
            </Modal>
            <Modal title="All Data" visible={isModalVisible} onOk={() => (setIsModalVisible(false))} footer='' onCancel={handleCancel} width={1000}>
                <div class="table-responsive">
                    <table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "50%" }}>Data</th>
                                <th style={{ width: "50%" }}>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ColumnName?.Meter_Data?.dailydata?.map((col) => (col?.Type == "datetime" ? <tr>
                                <td style={{ width: "50%" }}>{col?.Column}</td>
                                <td style={{ width: "50%" }}>{AllData[col?.Column] ? moment.utc(AllData[col?.Column]).format("DD-MM-YYYY hh:mm:ss A") : ''}</td>
                            </tr> : <tr>
                                <td style={{ width: "50%" }}>{col?.Column}</td>
                                <td style={col?.Column == 'UNITS' || col?.Column == 'ERRORBIN' || col?.Column == 'RAWDATA' ? { maxWidth: '500px' } : { width: "50%" }}>{AllData[col?.Column]}</td>
                            </tr>))}

                        </tbody>
                    </table>
                </div>
            </Modal>
            <section className="card-diff-section">
                <div className="section-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4><BootstrapTooltip title={`Table Name is ${props?.GSMdata?.UNIQUE_TABLE_NAME?.dailydata}`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
                                    <div className="card-header-action">
                                        <div id="filtergrm">

                                            {/* <button type="button" id="mqtt_get_cmd_301" class="btn btn-info mr-1" onClick={ongetScriptCodeIdWiseConfigretionData}>
                                                Get Data
                                            </button> */}
                                            <a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={() => {
                                                ongetHeardbeatData()
                                            }}>
                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                            </a>
                                            <div class="d-inline">
                                                <button class="btn btn-info" onClick={() => ongetStartAndEndDateByTable()}>
                                                    <i class="fa fa-download" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                            {/* <a class="btn btn-info text-white" data-toggle="tooltip" data-placement="bottom" title="Download" onClick={getreportCreacteInTableName}>
                                                <i class="fa fa-download" aria-hidden="true"></i>
                                            </a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {loader && (
                                        <div className="example">
                                            <Spin size="large" />
                                        </div>
                                    )}
                                    {heartData.length === 0 && !loader ? StatusCode == 503 ? <h4 className="text-center">Table Not Found</h4> : <h4 className="text-center">No Data Found</h4> : ''}
                                    {heartData.length > 0 &&
                                        <div className="table-responsive tw-h-screen tw-max-h-screen">
                                            <div id="table-1_wrapper" class="dataTables_wrapper no-footer">

                                                <table id="table_id" className="table table-borderless table-striped font-10">
                                                    <thead className='tw-sticky tw-z-50 tw-top-0 tw-bg-gray-100'>
                                                        <tr className="tw-border-b text-center">
                                                            {ColumnName?.Meter_Data?.dailydata?.length > 0 && ColumnName?.Meter_Data?.dailydata?.map((col) => (col?.Display == "Yes" ? GSMdata?.SCRIPTDECODE_ID==='MODUID1671705090942_METUID1665810585135'&&col.Column=='READING_RECORD'?<><th className="" onClick={() => onSort(col?.Column)}><BootstrapTooltip title={col?.Column}><div className='tw-flex tw-items-center tw-justify-center'>
                                                                <div >Conv. cons.</div>
                                                                {col?.Notes && <BootstrapTooltip title={col?.Notes} placement='top'><span><i className="ml-2 fas fa-info-circle tw-text-xs"></i></span></BootstrapTooltip>}
                                                                <div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div>
                                                            </div></BootstrapTooltip></th>
                                                            <th className="" onClick={() => onSort(col?.Column)}><BootstrapTooltip title={col?.Column}><div className='tw-flex tw-items-center tw-justify-center'>
                                                            <div >{col?.Header}</div>
                                                            {col?.Notes && <BootstrapTooltip title={col?.Notes} placement='top'><span><i className="ml-2 fas fa-info-circle tw-text-xs"></i></span></BootstrapTooltip>}
                                                            <div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div>
                                                        </div></BootstrapTooltip></th></>:col.Column == "METER_ENDTS" || col.Column == "METER_STARTTS" || col.Column == "METER_BCD_TS" || col.Column == "METER_TS" ? <><BootstrapTooltip title={col?.Column}><th className="" onClick={() => onSort(col?.Column)}><div className='tw-flex tw-items-center tw-justify-center'><span>{col.Column == "METER_BCD_TS" || col.Column == "METER_TS" ? 'Date' : col.Column == "METER_ENDTS" ? 'Date' : 'Date'}</span><div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div></div></th></BootstrapTooltip><BootstrapTooltip title={col?.Column}><th className="" onClick={() => onSort(col?.Column)}><div className='tw-flex tw-items-center tw-justify-center'><span>{col.Column == "METER_BCD_TS" || col.Column == "METER_TS" ? 'Time' : col.Column == "METER_ENDTS" ? 'Time' : 'Time'}</span><div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div></div></th></BootstrapTooltip></> : col.CF == true ? <><BootstrapTooltip title={col?.Column}><th className="" onClick={() => onSort(col?.Column)}><div className='tw-flex tw-items-center tw-justify-center'><span>{col?.Column}</span><div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div></div></th></BootstrapTooltip><BootstrapTooltip title={col?.Column}><th className=""><div className='tw-flex tw-items-center tw-justify-center'><span>{col?.Column+' With CF' }</span></div></th></BootstrapTooltip></> : <th className="" onClick={() => onSort(col?.Column)}><BootstrapTooltip title={col?.Column}><div className='tw-flex tw-items-center tw-justify-center'>
                                                                <div >{col?.Header}</div>
                                                                {col?.Notes && <BootstrapTooltip title={col?.Notes} placement='top'><span><i className="ml-2 fas fa-info-circle tw-text-xs"></i></span></BootstrapTooltip>}
                                                                <div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div>
                                                            </div></BootstrapTooltip></th> : ''))}
                                                            <th>ACTIONS</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {heartData.length > 0 && heartData.map((item, id) => (
                                                            <tr className="trheigth text-center">
                                                                {ColumnName?.Meter_Data?.dailydata?.length > 0 && ColumnName?.Meter_Data?.dailydata?.map((col) => (col?.Display == "No" ? '' :col.Column=='READING_RECORD'?<>
                                                                <td>
                                                                {Number(item[col.Column])*GSMdata?.CONVERSION_FACTOR}
                                                                </td>
                                                                <td>
                                                                {item[col.Column]}
                                                                </td>
                                                                </>: col?.Type == "datetime" ? col.Column == "METER_ENDTS" || col.Column == "METER_STARTTS" || col.Column == "METER_BCD_TS" || col.Column == "METER_TS" ? <><td style={{ minWidth: "100px" }}>{item[col.Column] ? moment.utc(item[col.Column]).format("DD-MM-YYYY") : ''}</td><td style={{ minWidth: "100px" }}>{item[col.Column] ? moment.utc(item[col.Column]).format("HH:mm:ss") : ''}</td></> : <td style={{ minWidth: "100px" }}>{item[col.Column] ? item[col.Column] != '0000-00-00 00:00:00' ? moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A") : item[col.Column] : ''}</td> : col?.Float != null && col?.Float != undefined ? col?.Division == true ?col.CF==true?<><td >{(item[col.Column] / col?.DivisionNumber).toFixed(col?.Float)}</td>
                                                                        <td>{((item[col.Column] / col?.DivisionNumber)*GSMdata?.CONVERSION_FACTOR).toFixed(col?.Float)}</td>
                                                                        </>
                                                                        :
                                                                    <td style={col.Column == 'UNITS' ? { minWidth: '400px' } :
                                                                        col.Column == 'ERRORBIN' ? { maxWidth: '400px' } : {}}>{col?.Float != null && col?.Float != undefined && item[col.Column] ? Number(item[col.Column]).toFixed(col?.Float) : item[col.Column]}</td> :col.CF==true?<><td >{col?.Float != null && col?.Float != undefined && item[col.Column] ? (Number(item[col.Column])*GSMdata?.CONVERSION_FACTOR).toFixed(col?.Float) : item[col.Column]}</td>
                                                                        <td>{((item[col.Column] / col?.DivisionNumber)*GSMdata?.CONVERSION_FACTOR).toFixed(col?.Float)}</td>
                                                                        </>
                                                                        : <td style={col.Column == 'UNITS' ? { minWidth: '400px' } :
                                                                            col.Column == 'ERRORBIN' ? { maxWidth: '400px' } : {}}>{col?.Float != null && col?.Float != undefined && item[col.Column] ? Number(item[col.Column]).toFixed(col?.Float) : item[col.Column]}</td> :
                                                                    col?.Division == true ?col.CF==true?<><td >{item[col.Column] / col?.DivisionNumber}</td>
                                                                    <td>{(item[col.Column] / col?.DivisionNumber)*GSMdata?.CONVERSION_FACTOR}</td>
                                                                    </>:
                                                                        <td style={col.Column == 'UNITS' ? { minWidth: '400px' } :
                                                                            col.Column == 'ERRORBIN' ? { maxWidth: '400px' } : {}}>{item[col.Column] / col?.DivisionNumber}</td> :col.CF==true?<><td >{item[col.Column]}</td>
                                                                            <td>{item[col.Column]!==null&&(item[col.Column]*GSMdata?.CONVERSION_FACTOR)}</td>
                                                                            </>:
                                                                        <td style={col.Column == 'UNITS' ? { minWidth: '400px' } : col.Column == 'ERRORBIN' ? { maxWidth: '400px' } : {}}>{item[col.Column]}</td>
                                                                ))}
                                                                <td style={{ minWidth: '100px' }}><a class="action-icon text-success" onClick={() => (onAllShowData(item))} ><span data-toggle="tooltip" data-placement="bottom" title="All Data"><i class="fa fa-database fa-lg font-13" aria-hidden="true"></i></span></a>&nbsp;
                                                                    <a class="action-icon text-info" onClick={() => onRowDate(item?.RAWDATA)}><span data-toggle="tooltip" data-placement="bottom" title="Raw Data"><i class="fa fa-info-circle fa-lg font-13" aria-hidden="true"></i></span></a>&nbsp;
                                                                    <a class="action-icon text-danger" onClick={() => (onDuplicate(item?.DUPLICATEBIN))}><span data-toggle="tooltip" data-placement="bottom" title="Duplicate Data"><i class="fa fa-clone fa-lg font-13" aria-hidden="true"></i></span></a>&nbsp;
                                                                    <a class="action-icon text-dark" onClick={() => (onError(item?.ERRORBIN))}><span data-toggle="tooltip" data-placement="bottom" title="Error Data"><i class="fa fa-exclamation-triangle fa-lg font-13" aria-hidden="true"></i></span></a></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <>
                                                    <div className="d-flex justify-content-between">
                                                        <div>Total Number Of Row {numberofPage}</div>
                                                        <div class="dataTables_length" id="table_id_length">
                                                            <label>
                                                                Show{" "}
                                                                <select
                                                                    name="table_id_length"
                                                                    aria-controls="table_id"
                                                                    class=""
                                                                    onChange={(e) => setPageSize(e.target.value)}
                                                                    value={PageSize}
                                                                >
                                                                    <option value={10}>10</option>
                                                                    <option value={25}>25</option>
                                                                    <option value={50}>50</option>
                                                                    <option value={100}>100</option>
                                                                </select>{" "}
                                                                entries
                                                            </label>
                                                        </div>
                                                        <Pagination
                                                            className="pagination-bar"
                                                            currentPage={currentPage}
                                                            totalCount={numberofPage || 1}
                                                            pageSize={PageSize}
                                                            onPageChange={(page) => setCurrentPage(page)}
                                                        />
                                                    </div>
                                                </>
                                            </div>
                                        </div>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

