// import React, { useEffect, useState } from 'react'
// import { getRolesById, getScheduleAnalyticsList } from '../../../Commoncomponet/Utils'
// import moment from 'moment'
// import { DatePicker } from 'antd'

// export default function ScheduleDashboard(props) {

//     const [DueDateTable, setDueDateTable] = useState({})
//     const [baseFilterObj, setbaseFilterObj] = useState({})
//     const [permision, setpermision] = useState({})
//     const [StatusLoad, setStatusLoad] = useState(false)
//     const [ConsumptionDate, setConsumptionDate] = useState(moment().format('DD-MM-YYYY'))
//     const userInfo = JSON.parse(window.localStorage.getItem("user_info"));

//     const onHandalTabFilter = () => {

//     }

//     const onHandaleRoles = async () => {
//         const data = await getRolesById(userInfo?.ROLE);
//         if (data.success === true) {
//             const obj = data.data.data[0];
//             setpermision({
//                 ...obj,
//                 ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0),
//                 ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5),
//                 ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2),
//                 ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3),
//                 ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1),
//                 ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4),
//                 ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS)
//             });
//         }
//     }

//     const ongetScheduleAnalyticsList = async (ConsumptionDate) => {
//         console.log(ConsumptionDate)
//         setStatusLoad(true)
//         const data = await getScheduleAnalyticsList({ schlogtableName: JSON.parse(props?.credentialdetails?.TABLE_JSON || '{}')?.schedule_log, schtableName: JSON.parse(props?.credentialdetails?.TABLE_JSON || '{}')?.schedule, permission: { CATEGORY0: permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view, CATEGORY1: permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view, CATEGORY2: permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY3: permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view, CATEGORY4: permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view, CATEGORY5: permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view },User_id:userInfo.UNIQUE_ID, ROLE: userInfo?.ROLE, date: moment(ConsumptionDate, 'DD-MM-YYYY').format('YYYY-MM-DD') })
//         if (data.success == true) {
//             setDueDateTable(data?.data?.dashboard_data)
//         setStatusLoad(false)
//         } else {
//             setStatusLoad(false)
//         }
//     }

//     useEffect(() => {
//         if (Object.keys(permision).length > 0) {
//         }
//     }, [permision])

//     useEffect(() => {
//         onHandaleRoles()
//     }, [])
//     return (
//         <>
//             <div className='tw-flex tw-w-full tw-divide-x md:tw-gap-3 tw-gap-2  tw-py-2 md:tw-px-3 tw-px-2' >
//                 <div className='tw-grid tw-grid-cols-2 tw-gap-2 tw-w-[100%] tw-h-fit border-right md:tw-pr-3 tw-pr-2' >
//                 {StatusLoad==false&&Object.keys(DueDateTable).length==0||StatusLoad==true ?<div className='tw-absolute !tw-opacity-100 tw-left-[20%] tw-top-[60%] tw-text-[20px]'>Please Load Data...</div>:''}

//                     <CardBody
//                         CardTitle='Schedule Analytics'
//                         className={'!tw-mt-0'}
//                         bodyClassName={`tw-py-2 md:tw-px-6 tw-px-3 tw-w-full md:tw-p-2 tw-font-semibold 2xl:tw-max-h-[340px] lg:tw-max-h-[380px] md:tw-max-h-[380px] tw-overflow-auto tw-o ${StatusLoad==false&&Object.keys(DueDateTable).length==0?'tw-opacity-25 ':StatusLoad==true?'tw-opacity-25 ':''} `}
//                         refreshClick={() => { ongetScheduleAnalyticsList(ConsumptionDate) }}
//                         StatusLoad={StatusLoad}
//                     >
//                         <>
//                             <div class="tw-flex  tw-items-center tw-justify-center">
//                                 <button class="tw-bg-transparent tw-transition tw-border-none hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
//                                     setConsumptionDate(moment(ConsumptionDate, 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'))
//                                     ongetScheduleAnalyticsList(moment(ConsumptionDate, 'DD-MM-YYYY').add(-1, 'days').format('DD-MM-YYYY'))

//                                 }} disabled={(StatusLoad==false&&Object.keys(DueDateTable).length==0)||StatusLoad==true}>
//                                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-left">
//                                         <path d="m15 18-6-6 6-6"></path>
//                                     </svg>
//                                 </button>
//                                 <div class="tw-w-fit tw-px-3">
//                                     <div class="tw-flex">
//                                         <span class="tw-font-semibold"><DatePicker bordered={false} onChange={(date, dateString) => {
//                                             if(dateString){
//                                             setConsumptionDate(dateString)
//                                             ongetScheduleAnalyticsList(dateString)
//                                             }

//                                         }} value={moment(ConsumptionDate || moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')} format='DD-MM-YYYY' disabled={(StatusLoad==false&&Object.keys(DueDateTable).length==0)||StatusLoad==true}/></span>
//                                     </div>
//                                 </div>
//                                 <button class="tw-bg-transparent tw-border-none tw-transition hover:tw-bg-indigo-100 tw-rounded-full tw-p-0.5 tw-text-indigo-500 hover:tw-text-indigo-600" onClick={() => {
//                                     setConsumptionDate(moment(ConsumptionDate, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY'))
//                                     ongetScheduleAnalyticsList(moment(ConsumptionDate, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY'))

//                                 }} disabled={(StatusLoad==false&&Object.keys(DueDateTable).length==0)||StatusLoad==true}>
//                                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right">
//                                         <path d="m9 18 6-6-6-6"></path>
//                                     </svg>
//                                 </button>
//                                 <div></div>
//                             </div>
//                             <TaskFilterButton
//                                 label="Total Selected Consumer"
//                                 count={DueDateTable?.total_consumer}
//                                 onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: -4, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
//                                 mainStyle="tw-text-red-700 hover:tw-bg-red-50 tw-border-b-red-700"
//                                 countStyle="tw-bg-red-800"
//                             />
//                             <TaskFilterButton
//                                 label="Total Consumer Daily Data Found"
//                                 count={DueDateTable?.daily_data_found}
//                                 onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: -3, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
//                                 mainStyle="tw-text-yellow-600 hover:tw-bg-yellow-50 tw-border-b-yellow-600"
//                                 countStyle="tw-bg-yellow-700"
//                             />
//                             <TaskFilterButton
//                                 label="Total Consumer Daily Data Not Found"
//                                 count={DueDateTable?.daily_data_not_found}
//                                 onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: -3, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
//                                 mainStyle="tw-text-green-600 hover:tw-bg-green-50 tw-border-b-green-600"
//                                 countStyle="tw-bg-green-700"
//                             />
//                             <TaskFilterButton
//                                 label="Total Consumer Data Push"
//                                 count={DueDateTable?.daily_total_push_consumer}
//                                 onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: -2, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
//                                 mainStyle="tw-text-blue-500 hover:tw-bg-blue-50 tw-border-b-blue-500"
//                                 countStyle="tw-bg-blue-600"
//                             />
//                             <TaskFilterButton
//                                 label="% of Data Push"
//                                 count={((DueDateTable?.daily_total_push_consumer/DueDateTable?.daily_data_found)*100).toFixed(2)+' %'}
//                                 onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: -2, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
//                                 mainStyle="tw-text-indigo-500 hover:tw-bg-indigo-50 tw-border-b-indigo-500"
//                                 countStyle="tw-bg-indigo-600"
//                             />
//                             {/* <TaskFilterButton
//                             label="Total Consumption"
//                             count={DueDateTable[0]?.PAST_1_15_Days}
//                             onClick={() => onHandalTabFilter({ ...baseFilterObj, DuedateStatus: -1, taskStatus: { type: "is", values: ['-1', '0', '3', '2'] } })}
//                             mainStyle="tw-text-indigo-400 hover:tw-bg-indigo-50 tw-border-b-indigo-400"
//                             countStyle="tw-bg-indigo-500"
//                         /> */}

//                         </>
//                     </CardBody>
//                 </div>
//             </div>
//         </>
//     )
// }

// const TaskFilterButton = ({ label, count, onClick, mainStyle, countStyle, countinlinestyle = {}, mainInlineStyle = {}, icon }) => (
//     <div style={{ ...mainInlineStyle, borderBottomStyle: 'dotted', borderBottomWidth: '1px' }}
//         className={`tw-justify-center tw-font-medium tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50 tw-flex tw-gap-2 tw-text-base tw-items-center hover:tw-rounded tw-p-2 tw-cursor-pointer tw-duration-150 ${mainStyle ? mainStyle : ""} `} onClick={onClick}>
//         <div className='' >{icon}</div>
//         <label className="tw-font-semibold peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70 tw-mb-0">{label}</label>
//         <div style={countinlinestyle} className={`tw-ml-auto tw-text-white tw-cursor-pointer tw-inline-flex tw-items-center tw-px-2.5 tw-py-1 tw-text-xs tw-font-semibold tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 tw-rounded-full tw-border-transparent tw-gap-1 ${countStyle ? countStyle : ""}`}>{count || 0}</div>
//     </div>
// );

// const CardBody = ({ children, CardTitle, isBodyClass = true, className, bodyClassName, refreshClick,StatusLoad }) => {
//     return (
//         <div className={`tw-text-card-foreground tw-shadow-md tw-w-full tw-my-4 tw-py-2 border tw-bg-card tw-rounded ${className ?? className}`} style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0px 5px 10px 0px" }} >
//             <div className='tw-flex tw-flex-col tw-space-y-1.5' >
//                 <h5 className='tw-font-semibold tw-leading-none tw-tracking-tight border-bottom tw-px-2 tw-py-3 tw-mb-0 d-flex tw-justify-between' >
//                     <span>
//                         {CardTitle}
//                     </span>
//                     <div>
//                         <div><button class={`btn btn-info text-white ${StatusLoad==true?'btn-progress disabled:':''}`} onClick={refreshClick} ><i class="fa fa-refresh" aria-hidden="true"></i></button>
//                         </div>
//                     </div>
//                 </h5>

//             </div>
//             <div className={`${isBodyClass ? "tw-grid tw-gap-1 tw-px-6 tw-pb-4" : ""} ${bodyClassName ?? bodyClassName}`} >
//                 {children}
//             </div>
//         </div>
//     )
// }

import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { DatePicker, Modal, Space, Spin } from 'antd';
import { BootstrapTooltip } from '../../../Commoncomponet/common';
import { getScheduleAnalyticsOnClick ,getScheduleAnalyticsByDate} from '../../../Commoncomponet/Utils';
import loaderGif from '../../../assets/img/loading_chart.gif'

export default function  ScheduleDashboard (props) {
    const chartRef = useRef();
    const expandChartRef = useRef();

    const [loader, setLoader] = useState(true);
    const [chartLoader, setChartLoader] = useState(true);
    const [scheduleAnalyticsData, setScheduleAnalyticsData] = useState([]);

    const [onClickData, setOnClickData] = useState(null);
    const [onClickModel, setOnClickModel] = useState(false);
    const [onClickLoader, setOnClickLoader] = useState(false);
    const [isExpandedModalOpen, setisExpandedModalOpen] = useState(false)
    const [expandChartData, setexpandChartData] = useState(null);
    const [expandLoader, setExpandLoader] = useState(false);
    const [scheduleAnalyticsPercentageData, setScheduleAnalyticsPercentageData] = useState([]);
    const [dates, setDates] = useState({ start: '', end: '' });

   
    const onExpandButton = (header) => {
        setisExpandedModalOpen(true);
        setexpandChartData((prev) => ({ ...prev, title: header }));
    }
    const onExpandSubmitClick = async () => {
        console.log("Dates", dates);
        if (dates && dates.start && dates.end) {
            setExpandLoader(true);
            const startDate = moment(dates.start, 'DD-MM-YYYY').format('YYYY-MM-DD');
            const endDate = moment(dates.end, 'DD-MM-YYYY').format('YYYY-MM-DD');

            const data = await getScheduleAnalyticsByDate({ start_date: startDate, end_date: endDate,schedule_table_name:JSON.parse(props?.credentialdetails?.TABLE_JSON||'{}')?.schedule });
            if (data.success === true) {
                const ChartArray = data.data.data;
                const obj = { unique_id: ChartArray.map((item) => item.unique_id), labels: ChartArray.map((item) => moment.utc(item.created_at).format("DD-MM-YYYY")), data: { "matched_consumers": ChartArray.map((item) => item.matched_consumers_length ?? 0), "unmatched_consumers": ChartArray.map((item) => item.unmatched_consumers_length ?? 0), "daily_data_found_true": ChartArray.map((item) => item.daily_data_found_true_length ?? 0), "daily_data_found_false": ChartArray.map((item) => item.daily_data_found_false_length ?? 0), "script_decode_id_found_true": ChartArray.map((item) => item.script_decode_id_found_true_length ?? 0), "script_decode_id_found_false": ChartArray.map((item) => item.script_decode_id_found_false_length ?? 0) } };
                setexpandChartData((prev) => ({ ...prev, data: obj }));
                setExpandLoader(false);

            } else {
                console.log("Something Went Wrong");
                setExpandLoader(false);
            }
        }
    }

    useEffect(() => {
        
        const end_date = moment().format('YYYY-MM-DD');
        const start_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
        ongetScheduleAnalyticsDataByDate({ end_date, start_date });

    }, [])

    const ongetScheduleAnalyticsDataByDate = async (info) => {
        setChartLoader(true);
        setLoader(false);
        const data = await getScheduleAnalyticsByDate(info || {});
        if (data.success === true) {
            const ChartArray = data.data.data;
            const obj = { unique_id: ChartArray.map((item) => item.unique_id), labels: ChartArray.map((item) => moment.utc(item.created_at).format("DD-MM-YYYY")), data: { "matched_consumers": ChartArray.map((item) => item.matched_consumers_length ?? 0), "unmatched_consumers": ChartArray.map((item) => item.unmatched_consumers_length ?? 0), "daily_data_found_true": ChartArray.map((item) => item.daily_data_found_true_length ?? 0), "daily_data_found_false": ChartArray.map((item) => item.daily_data_found_false_length ?? 0), "script_decode_id_found_true": ChartArray.map((item) => item.script_decode_id_found_true_length ?? 0), "script_decode_id_found_false": ChartArray.map((item) => item.script_decode_id_found_false_length ?? 0) } };
            const obj1 = {
                labels: ChartArray.map((item) => moment.utc(item.created_at).format("DD-MM-YYYY")), data: ChartArray.map((item) => {
                    return parseFloat(((item.matched_consumers_length / item.all_consumers) * 100).toFixed(2)) || 0;
                })
            }
            console.log("obj1", obj1);
            // 1.2 new added
            setScheduleAnalyticsPercentageData(obj1);

            setScheduleAnalyticsData(obj)
            setChartLoader(false);
        } else {
            console.log("Something Went Wrong");
            setLoader(false);
            setChartLoader(false);
        }
    };

    const ongetOnClickData = async (col, unique_id, title) => {

        setOnClickLoader(true);
        setOnClickModel(true);
        const data = await getScheduleAnalyticsOnClick({ col: col, unique_id: unique_id });
        if (data.success === true) {
            setOnClickData({ title: `${title} Details`, data: data.data.details });
            setOnClickLoader(false);

        } else {
            console.log("Something Went Wrong");
            setOnClickLoader(false);
        }

    };
    const handleOnClickModelClose = () => {
        setOnClickModel(false);
        setOnClickData(null);
    }


    const optionsForChartJS = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {

                ticks: {
                    maxRotation: 90,
                },
            },
            y: {
                grace: "10%",
                ticks: {
                    fontSize: 70
                    }
            }
        },
        animation: false,
        // animation: {
        // 	duration: 1000,
        // 	easing: 'easeInBounce',
        // 	onComplete: () => {
        // 		delayed = true;
        // 	},
        // 	delay: (context) => {
        // 		let delay = 0;
        // 		if (context.type === 'data' && context.mode === 'default' && !delayed) {
        // 			delay = context.dataIndex * 300 + context.datasetIndex * 100;
        // 		}
        // 		return delay;
        // 	},
        // },

        plugins: {
            legend: {
                position: 'bottom',
                fullSize: false,
                labels: {
                    pointStyleWidth: 10,
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    font: function (context) {
                        var w = context.chart.width;
                        return {
                            size: w < 512 ? 9 : 11,

                        };
                    },
                }
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                        modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
                    },
                    mode: "x",
                },
                pan: {
                    enabled: true,
                    mode: "x",
                }
            },
            datalabels: {
                align: 'end',
                anchor: 'end',
                clamp: true,
                clip: true,
                color: "black",
                padding: {
                    top: 0,
                    bottom: 0
                },
                font: function (context) {
                    var w = context.chart.width;
                    return {
                        size: w < 512 ? 12 : 12,

                    };
                },
            }
        },
        layout: {
            padding: 20
        },
    };
   
    const dataForChartJs = {
        labels: scheduleAnalyticsData?.labels || [],
        datasets: [
            {
                label: "Push Data of Consumers",
                data: scheduleAnalyticsData?.data?.matched_consumers,
                barPercentage: 1.0,
                backgroundColor: 'rgb(0, 126, 51)',
                borderRadius: 5,
            },
            {
                label: "Not Push Data of Consumers",
                data: scheduleAnalyticsData?.data?.unmatched_consumers,
                barPercentage: 1.0,
                backgroundColor: 'rgb(50,225,79)',
                borderRadius: 5,
            },
            {
                label: "Daily Data Found",
                data: scheduleAnalyticsData?.data?.daily_data_found_true,
                barPercentage: 1.0,
                backgroundColor: 'rgb(0,104,138)',
                borderRadius: 5,
            },
            {
                label: "Daily Data Not Found",
                data: scheduleAnalyticsData?.data?.daily_data_found_false,
                barPercentage: 1.0,
                backgroundColor: 'rgb(85, 200, 245)',
                borderRadius: 5,
            },
            // 1.2 new added for script decode id
            {
                label: "Script Decode Id Found",
                data: scheduleAnalyticsData?.data?.script_decode_id_found_true,
                barPercentage: 1.0,
                backgroundColor: 'rgb(0,104,138)',
                borderRadius: 5,
            },
            // 1.2 new added for script decode id
            // {
            //     label: "Script Decode Id Found False",
            //     data: scheduleAnalyticsData?.data?.script_decode_id_found_false,
            //     barPercentage: 1.0,
            //     backgroundColor: 'rgb(85, 200, 245)',
            //     borderRadius: 5,
            // },

        ],
    };
   
    const expandDataForChartJs = {
        labels: expandChartData?.data?.labels || [],
        datasets: [
            {
                label: "Matched Consumers",
                data: expandChartData?.data?.data?.matched_consumers,
                barPercentage: 1.0,
                backgroundColor: 'rgb(0, 126, 51)',
                borderRadius: 5,
            },
            {
                label: "Unmatched Consumers",
                data: expandChartData?.data?.data?.unmatched_consumers,
                barPercentage: 1.0,
                backgroundColor: 'rgb(50,225,79)',
                borderRadius: 5,
            },
            {
                label: "Daily Data Found True",
                data: expandChartData?.data?.data?.daily_data_found_true,
                barPercentage: 1.0,
                backgroundColor: 'rgb(0,104,138)',
                borderRadius: 5,
            },
            {
                label: "Daily Data Found False",
                data: expandChartData?.data?.data?.daily_data_found_false,
                barPercentage: 1.0,
                backgroundColor: 'rgb(85, 200, 245)',
                borderRadius: 5,
            },
            // 1.2 new added for script decode id
            {
                label: "Script Decode Id Found True",
                data: expandChartData?.data?.data?.script_decode_id_found_true,
                barPercentage: 1.0,
                backgroundColor: 'rgb(0,104,138)',
                borderRadius: 5,
            },
            // 1.2 new added for script decode id
            {
                label: "Script Decode Id Found False",
                data: expandChartData?.data?.data?.script_decode_id_found_false,
                barPercentage: 1.0,
                backgroundColor: 'rgb(85, 200, 245)',
                borderRadius: 5,
            },

        ],
    };
    const dataForPercentageChartJs = {
        labels: scheduleAnalyticsPercentageData?.labels || [],
        datasets: [
            {
                label: "Percentage of Consumers",
                data: scheduleAnalyticsPercentageData?.data,
                barPercentage: 0.2,
                backgroundColor: 'rgb(0, 126, 51)',
                borderRadius: 5,
            
            },
        ]
    }

    const onClick = (event) => {
        const { datasetIndex, index } = getElementAtEvent(chartRef.current, event)[0] || {};
        console.log("datasetIndex", datasetIndex, "index", index);
        if (datasetIndex === 0) {
            console.log("Matched Consumers: ", scheduleAnalyticsData?.labels[index], "Unique_id", scheduleAnalyticsData?.unique_id[index]);
            ongetOnClickData("matched_consumers", scheduleAnalyticsData?.unique_id[index], "Matched Consumer");
        } else if (datasetIndex === 1) {
            console.log("Unmatched Consumers: ", scheduleAnalyticsData?.labels[index], "Unique_id", scheduleAnalyticsData?.unique_id[index]);
            ongetOnClickData("unmatched_consumers", scheduleAnalyticsData?.unique_id[index], "Unmatched Consumers");
        } else if (datasetIndex === 2) {
            console.log("Daily Data Found True: ", scheduleAnalyticsData?.labels[index], "Unique_id", scheduleAnalyticsData?.unique_id[index]);
            ongetOnClickData("daily_data_found_true", scheduleAnalyticsData?.unique_id[index], "Daily Data Found True");
        } else if (datasetIndex === 3) {
            console.log("Daily Data Found False: ", scheduleAnalyticsData?.labels[index], "Unique_id", scheduleAnalyticsData?.unique_id[index]);
            ongetOnClickData("daily_data_found_false", scheduleAnalyticsData?.unique_id[index], "Daily Data Found False");
            // 1.2 new added for script decode id
        } else if (datasetIndex === 4) {
            console.log("Script Decode Id Found True: ", scheduleAnalyticsData?.labels[index], "Unique_id", scheduleAnalyticsData?.unique_id[index]);
            ongetOnClickData("script_decode_id_found_true", scheduleAnalyticsData?.unique_id[index], "Script Decode Id Found True");
            // 1.2 new added for script decode id
        } else if (datasetIndex === 5) {
            console.log("Script Decode Id Found False: ", scheduleAnalyticsData?.labels[index], "Unique_id", scheduleAnalyticsData?.unique_id[index]);
            ongetOnClickData("script_decode_id_found_false", scheduleAnalyticsData?.unique_id[index], "Script Decode Id Found False");
        }
    }
   
    const handleExpandedModal = () => {
        setisExpandedModalOpen(false);
        setexpandChartData(null);
        setExpandLoader(false);
        setDates({ start: '', end: '' });
        // setisExpandedModalOpen(false);
        // setexpandedChartData(null);
        // setExInstallationChartData({ barData: [], date: [] })
        // setExEventChartData({ total: {}, date: [] })
        // setExMonthBillCartData({ total: {}, date: [] })
        // if (myChart !=h undefined) {
        // 	myChart.destroy()
        // }
        // setChartLoader(false)
        // setDates({ start: '', end: '' })
    }
    const onChange = (date, dateString) => {
        setDates({ ...dates, start: dateString })
    };
    const onChanges = (date, dateString) => {
        setDates({ ...dates, end: dateString })
    };


    return (
        <>
            <Modal title={expandChartData?.title ?? "Expand Modal"} visible={isExpandedModalOpen} maskClosable={false} footer='' onCancel={handleExpandedModal} width={1500}>

                <div className="d-flex tw-justify-between tw-items-center">
                    <div className="d-flex tw-justify-center tw-items-end">

                        <Space direction="horizontal">
                            <div className="d-flex flex-column">Start In
                                <DatePicker value={dates?.start !== '' ? moment(dates?.start, "DD-MM-YYYY") : null} onChange={onChange} format='DD-MM-YYYY' name='start' picker="date" /></div>
                            <div className="d-flex flex-column" >End In
                                <DatePicker value={dates?.end !== '' ? moment(dates?.end, "DD-MM-YYYY") : null} onChange={onChanges} name='end' format='DD-MM-YYYY' picker="date" /></div>
                        </Space>



                        <div>
                            <a className={`btn btn-danger text-white ml-3 ${expandLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onExpandSubmitClick())}>Submit</a>
                        </div>

                    </div>

                    {/* {exInstallationChartData?.date.length || expandedChartData.date.length > 0 || exMonthBillChartData.date.length > 0 || exEventChartData.date.length > 0 ?
                        <div>
                            <div className="tw-mb-1">
                                <small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> You can also do Zoom and Scroll using your mouse</small>
                            </div>
                            <div className="tw-flex tw-justify-end tw-items-center">
                                <button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} disabled={zoomInEnable} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
                                    <div className="tw-flex tw-items-center fa-lg">
                                        <i class="fas fa-search-plus" aria-hidden="true"></i>
                                    </div>
                                </BootstrapTooltip>
                                </button>
                                <button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} disabled={zoomOutEnable} onClick={zoomOut}>
                                    <BootstrapTooltip title='ZoomOut'>
                                        <div className="tw-flex tw-items-center fa-lg">
                                            <i class="fas fa-search-minus" aria-hidden="true"></i>
                                        </div>
                                    </BootstrapTooltip>
                                </button>

                                <button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
                                    <BootstrapTooltip title='Left scroll'>
                                        <div className="tw-flex tw-items-center fa-lg">
                                            <i class="fas fa-caret-left" aria-hidden="true"></i>
                                        </div>
                                    </BootstrapTooltip>
                                </button>
                                <button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
                                    <BootstrapTooltip title='Right scroll'>
                                        <div className="tw-flex tw-items-center fa-lg">
                                            <i class="fas fa-caret-right" aria-hidden="true"></i>
                                        </div>
                                    </BootstrapTooltip>
                                </button>
                            </div>

                        </div>
                        // : ''} */}
                </div>

                {/* {expandedChartData.date.length > 0 ? <div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={expandChartRef} options={optionsExpanded} data={exInstantData} /> </div> : ''} */}

                {/* chart display */}
                {expandChartData?.data && expandChartData?.data?.labels?.length > 0 && !expandLoader ? <div className='tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5 '><Bar ref={expandChartRef} options={optionsForChartJS} data={expandDataForChartJs} /></div> : ""}
                {/* no data condition */}
                {expandChartData?.data?.labels?.length === 0 && !expandLoader && <div className='tw-mt-2 tw-h-[560px] tw-flex tw-items-center tw-justify-center tw-px-5'> No Data</div>}
            </Modal>

            <Modal title={onClickData?.title ?? ''} visible={onClickModel} footer='' maskClosable={false} onCancel={handleOnClickModelClose} width={800}>

                {onClickModel &&

                    <div className='tw-max-h-[75dvh] tw-overflow-auto'>
                        {onClickLoader ? <div className=" tw-h-[75dvh] tw-flex tw-items-center tw-justify-center"><Spin /></div> :
                            <table className="table border table-borderless tw-w-full tw-border-collapse tw-border tw-border-gray-300">
                                <thead className='tw-sticky tw-top-0'>
                                    <tr className="tw-bg-gray-100">
                                        <th className="tw-border tw-border-gray-300 tw-p-2">Sr No</th>
                                        <th className="tw-border tw-border-gray-300 tw-p-2">Address Company Name</th>
                                        <th className="tw-border tw-border-gray-300 tw-p-2">DEVICE_SERIAL_NO</th>
                                        <th className="tw-border tw-border-gray-300 tw-p-2">INSTALLATION_NUMBER</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {/* 1.2 remover 3 col  */}
                                    {onClickData?.data?.map((item,id) => (
                                           <tr key={item.UNIQUE_ID} className="trheigth even:tw-bg-gray-100 hover:tw-bg-[#D8D8D8]">
                                           <td className="tw-border tw-border-gray-300 tw-p-2">{id + 1}</td>
                                           <td className="tw-border tw-border-gray-300 tw-p-2">{item.address_compony_name}</td>
                                           <td className="tw-border tw-border-gray-300 tw-p-2">{item.DEVICE_SERIAL_NO}</td>
                                           <td className="tw-border tw-border-gray-300 tw-p-2">{item.INSTALLATION_NUMBER}</td>

                                       </tr>
                                    ))}
                                </tbody>
                            </table>

                        }
                    </div>}



            </Modal>
                {loader ? (
                    <div className="main-content">
                        <div class="loader"></div>
                    </div>
                ) : (
                    ""
                )}
                
                <section class="section mt-4">
                    <div class="section-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card card-danger">
                                    <div class="row">
                                        <div class="col-12 ">
                                            <div class="card-header"  >
                                                <h4><span className="mr-2">Push Data Analytics count</span><BootstrapTooltip title='Find Data In schedule_api_push_data_analytics_history table' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
                                                <div class="card-header-action">
                                                    <a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('Schedule Api Push Data Analytics') }}>Expand</a>
                                                </div>
                                            </div>

                                            <div class="card-body heartbeatspace " style={{ position: "relative", height: "395px" }}>
                                            {
                                                    chartLoader ? (
                                                        <div className="tw-flex tw-justify-center">
                                                            <img className="loader_center tw-text-center" src={loaderGif} alt="Loading..." />
                                                        </div>
                                                    ) : (
                                                        scheduleAnalyticsData?.labels?.length === 0 ? (
                                                            <div className="tw-mt-2 tw-h-full tw-flex tw-items-center tw-justify-center tw-px-5">
                                                                No Data
                                                            </div>
                                                        ) : (
                                                            <Bar
                                                                ref={chartRef}
                                                                onClick={onClick}
                                                                options={optionsForChartJS}
                                                                data={dataForChartJs}
                                                            />
                                                        )
                                                    )
                                                }
                                                {/* <Bar ref={chartRef} onClick={onClick} options={optionsForChartJS} data={dataForChartJs} /> */}
                                            </div>
                                            <p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>Bar chart</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="card card-danger">
                                    <div class="row">
                                        <div class="col-12 ">
                                            <div class="card-header"  >
                                                <h4><span className="mr-2">Push Data Analytics in Percentage</span><BootstrapTooltip title='Find Data In schedule_api_push_data_analytics_history table' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
                                                <div class="card-header-action">
                                                    <a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('Schedule Analysis Percentage Date Wise Chart') }}>Expand *</a>
                                                </div>
                                            </div>
                                            <div class="card-body heartbeatspace !tw-p-0 " style={{ position: "relative", height: "395px" }}>
                                                {
                                                    chartLoader ? (
                                                        <div className="tw-flex tw-justify-center">
                                                            <img className="loader_center tw-text-center" src={loaderGif} alt="Loading..." />
                                                        </div>
                                                    ) : (
                                                        scheduleAnalyticsPercentageData?.labels?.length === 0 ? (
                                                            <div className="tw-mt-2 tw-h-full tw-flex tw-items-center tw-justify-center tw-px-5">
                                                                No Data
                                                            </div>
                                                        ) : (
                                                            <Bar
                                                                options={optionsForChartJS}
                                                                data={dataForPercentageChartJs}
                                                            />
                                                        )
                                                    )
                                                }
                                                {/* {scheduleAnalyticsData?.labels?.length === 0 && !chartLoader && <div className='tw-mt-2 tw-flex tw-items-center tw-justify-center tw-px-5'> No Data</div>} */}
                                            </div>
                                            <p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>Bar chart</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </section>
        </>
    )
}